import React, { useEffect, useRef, useState, useContext } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../context/darkModeContext";
import "./adminappbar.scss"
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export const AdminAppBar = () => {
  const Navigate = useNavigate();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const soarxactuser = localStorage.getItem("mxactiveuser");
  useEffect(()=>{
    const fetchData = async () => {
      if(window.ethereum){
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0]
        console.log(soarxactuser, wa)
        if(wa.toLowerCase()!=soarxactuser.toLowerCase()){
          Navigate("/")
        }
      }
    }
    //fetchData()
  },[])
  const items = [
    {
      url: "/user",
      label: "Home",
      icon: "pi pi-home",
    },
  ];
  const start = (
    <img alt="logo" src="/favicon.png" height="40" className="mr-2"></img>
  );
  const end = (
    <div className="flex align-items-center gap-2">
      {darkMode ? (
        <InputSwitch checked={true} onClick={toggle} />
      ) : (
        <InputSwitch onClick={toggle} />
      )}
      <Button
        label="Log Out"
        icon="pi pi-power-off"
        severity="danger"
        outlined
        onClick={() => (window.location.href = "/")}
      />
    </div>
  );
  return (
    <div className="adminappbar">
      <div>
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  )
}
