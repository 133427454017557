import React, { useEffect, useRef, useState } from "react";
import "./supergold.scss";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as Yup from "yup";
export const SuperGold = () => {
    const toast = useRef(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [products, setProducts] = useState([]);
    const url = process.env.REACT_APP_HOST_ADDR;
    const validationSchema = Yup.object({
      amt: Yup.number("Invalid Amount Found").required("Amount can not be blank"),
    });
    const formik = useFormik({
      initialValues: {
        amt: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const { amt } = values;
        setButtonDisabled(true);
        const resp = await axios.post(url + "/admin/setgold", {
          amt,
        });
        if (resp.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Disbursh Mode Set Successfully.",
          });
          setButtonDisabled(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Disbursh Mode Set Successfully.",
          });
          setButtonDisabled(false);
        }
      },
    });
    useEffect(() => {
      const fetchData = async () => {
        const resp = await axios.get(url + "/admin/getsupergold");
        setProducts(resp.data.spgold);
        //setTotalSilver(resp.data.totgold)
      };
      fetchData();
    }, []);
    const isFormFieldValid = (name) =>
      !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
      return (
        isFormFieldValid(name) && (
          <small className="p-error">{formik.errors[name]}</small>
        )
      );
    };
    const handleSubmit = () => {
        setButtonDisabled(true);
        var dt = "1";
        axios
          .post(url + "/admin/setsupergold", {
            dt,
          })
          .then(function (resp) {
            if (resp.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Super Gold Distributted Successfully.",
              });
            }
            setButtonDisabled(false);
          });
      };
      const updateHandle = async (memid)=>{
       var amt = document.getElementById("_y" + memid).value
       document.getElementById("_x"+memid).innerHTML = amt
       const resp = await axios.post(url + "/admin/updatesupgold",{
        memid,
        amt
       })
       if(resp.status===200){
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Value Updated Successfully.",
        });
       }else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong.",
        });
       }
       //var i = document.getElementsByClassName("_x" + memid).innerHTML
       //alert(i)
      //  console.log(resp.data)
      }
  return (
    <div className="supergold">
        <Toast ref={toast} />
        {isButtonDisabled === false ? (
          <Button
            label="Submit"
            type="button"
            onClick={() => handleSubmit()}
          />
        ) : (
          <Button
            type="button"
            label="Proccessing..."
            icon="pi pi-spin pi-spinner"
          />
        )}
        <div className="card" style={{ marginTop: 5 }}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>SL No</TableCell>
            <TableCell>Member Id</TableCell>
            <TableCell>Seniour Id</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">AFT LEVEL</TableCell>
            <TableCell align="left">Total</TableCell>
            <TableCell align="left">New Val</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row, index) => (
            <TableRow
              key={row.memberid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                {index*1+1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.memberid}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.upgold}
              </TableCell>
              <TableCell align="left"><span id={"_x"+row.memberid}>{(row.ownpoint).toFixed(2)}</span> </TableCell>
              <TableCell align="left"><span>{(row.aft_dis).toFixed(2)}</span> </TableCell>
              <TableCell align="left"><span>{(row.aft_dis+row.ownpoint).toFixed(2)}</span> </TableCell>
              <TableCell align="left"><InputText size="small" id={"_y"+row.memberid}  /></TableCell>
              <TableCell align="left"><Button size="small" label="Confirm" onClick={()=>updateHandle(row.memberid)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
    </div>
  )
}
