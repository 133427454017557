import React, { useEffect, useRef, useState } from "react";
import "./reward.scss";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
export const Reward = () => {
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [products, setProducts] = useState([]);
  const url = process.env.REACT_APP_HOST_ADDR;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getreward");
      setProducts(resp.data.data);
    };
    fetchData();
  }, []);
  const handleSubmit = () => {
    setButtonDisabled(true);
    var dt = "1";
    axios
      .post(url + "/admin/setreward", {
        dt,
      })
      .then(function (resp) {
        if (resp.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Reward Distributted Successfully.",
          });
        }
        setButtonDisabled(false);
      });
  };
  return (
    <div className="reward">
      <Toast ref={toast} />
      {isButtonDisabled === false ? (
        <Button label="Submit" type="button" onClick={() => handleSubmit()} />
      ) : (
        <Button
          type="button"
          label="Proccessing..."
          icon="pi pi-spin pi-spinner"
        />
      )}
      <div className="card" style={{ marginTop: 5 }}>
        <DataTable
          value={products}
          size="small"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="i" header="SL NO"></Column>
          <Column field="_memberid" header="Member"></Column>
          <Column field="_reward" header="Reward"></Column>
          <Column field="diff" header="Diff"></Column>
          <Column field="_paid_times" header="Paid"></Column>
        </DataTable>
      </div>
    </div>
  );
};
