import React, { useEffect, useRef, useState } from "react";
// import "./downline.scss";
import { Card } from "primereact/card";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "primereact/button";
import moment from "moment"
export const StackList = () => {
  const [products, setProducts] = useState([]);
  const url = process.env.REACT_APP_HOST_ADDR;
  useEffect(() => {
    if (window.ethereum) {
      const fetchData = async () => {
        const adrs = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const wa = adrs[0];
        const resp = await axios.post(url + "/user/getstack",{wa});
        //console.log(resp.data.downline);
        setProducts(resp.data.stackdata);
      };
      fetchData();
    }
  }, []);
  return (
    <div className="stacklist">
      <div className="card" style={{ marginTop: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>DATE</TableCell>
                <TableCell align="left">USDT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow
                  key={row.memberid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {moment().utcOffset(330).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="left">{row.usdtval}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
