import React, { useEffect, useState, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import "./emailsetup.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
export const EmailSetup = () => {
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const [btnCheck, setBtnCheck] = useState(0)
  const [btnSubmit, setBtnSubmit] = useState(0)
  const toast = useRef(null);
  useEffect(()=>{
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
       formik.setFieldValue("actaddress",wa)
      }
    }
    fetchData()
  },[])
  const validationSchema = Yup.object({
    actaddress: Yup.mixed("Invalid Investor Id").required("Top-Up Id cant not be blank."),
    email: Yup.mixed("Invalid Investor Id").required("Top-Up Id cant not be blank."),
    memname: Yup.mixed("Invalid Investor Id").required("Top-Up Id cant not be blank."),
    otp: Yup.mixed("Invalid Investor Id").required("Top-Up Id cant not be blank."),
  });
  const formik = useFormik({
    initialValues: {
      actaddress: "",
      email: "",
      memname:"",
      otp: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnSubmit(1)
      const resp = await axios.post(url + "/user/masteremailconf", {values})
      if(resp.status===200){
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Email Linked Successfully",
        });
        setTimeout(() => {
          Navigate("/user/")
        }, 2000);
      }else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong....",
        });
      }
        
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const show = (x, title, msg) => {
    toast.current.show({ severity: x, summary: title, detail: msg });
  };
  const sendOTP = async ()=>{
    const wa= formik.values.actaddress
    const email = formik.values.email
    const memname = formik.values.memname
    if(wa==="" || email == "" || memname == ""){
      alert("Blank")
      return false
    }
    const resp = await axios.post(url + "/user/masteremail", {
      wa,
      email,
      memname
    });
    if(resp.status===201){
      setBtnCheck(1)
    }else{
      alert("Email Used As Master")
    }
  }
  return (
    <div className="emailsetup">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-7">
          <div className="p-1 border-round-sm font-bold">
            <Card className="content" title="Master Email Setup">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group flex flex-column gap-2">
                  <InputText
                      type="text"
                      name="actaddress"
                      className="p-inputtext-sm"
                      placeholder="Email Address"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values.actaddress}
                    />
                    <InputText
                      type="email"
                      name="email"
                      className="p-inputtext-sm"
                      placeholder="Email Address"
                      autoFocus
                      disabled={btnCheck===0?false:true}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <InputText
                      type="text"
                      name="memname"
                      className="p-inputtext-sm"
                      placeholder="Your Name"
                      onChange={formik.handleChange}
                      value={formik.values.memname}
                    />
                    <Button label="Check" severity="warning" onClick={()=>sendOTP()} disabled={btnCheck===0?false:true} />
                    <Divider />
                    <InputText
                      type="text"
                      name="otp"
                      className="p-inputtext-sm"
                      placeholder="OTP"
                      disabled={btnCheck===0?true:false}
                      onChange={formik.handleChange}
                      value={formik.values.otp}
                    />
                    {btnSubmit===0?
                    <Button type="submit" label="Confirm" severity="info" disabled={btnCheck===0?true:false} />
                    :
                    <Button type="button" label="Confirming..." severity="info" disabled={btnCheck===0?true:false} />
                    }
                  </div>
                  <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
                    This is your master Account for overall trasaction.
                  </Alert>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
