import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
export const GlobalUsdtOff = () => {
  const [smemberid, setSMemberId] = useState("");
  const [ememberid, setEMemberId] = useState("");
  const [pro, setPro] = useState(0);
  const [data, setData] = useState([]);
  const [memId, setMemId] = useState("");
  const [totInvest, setTotInvest] = useState("");
  const [totIncome, setTotIncome] = useState("");
  const [withdraw, setWithdraw] = useState("");
  const [reward, setReward] = useState("");
  const [stopId, setStopId] = useState("");
  const url = process.env.REACT_APP_HOST_ADDR;
  const claculateData = async () => {
    setPro(1);
    const resp = await axios.post(url + "/admin/claculateGlobalData", {
      smemberid,
      ememberid,
    });
    if (resp.status === 200) {
      setPro(0);
      setSMemberId("");
      setEMemberId("");
    } else {
    }
  };
  const fetchclaculateData = async () => {
    setPro(1);
    const resp = await axios.post(url + "/admin/fetchclaculateData", {
      smemberid,
      ememberid,
    });
    if (resp.status === 200) {
      setPro(0);
      setMemId(resp.data.smemberid);
      setData(resp.data.data);
      setTotInvest(resp.data.totinvest);
      setWithdraw(resp.data.withdraw);
      setTotIncome(resp.data.TotalInacome);
      setReward(resp.data.reward);
    } else {
    }
  };
  const custDate = (data) => {
    return <>{moment(data.tran_date).format("YYYY-MM-DD")}</>;
  };
  const custDate1 = (data) => {
    return <>{moment(data.last_tran_date).format("YYYY-MM-DD")}</>;
  };
  const makeStop = async (_id) => {
    const resp = await axios.post(url + "/admin/stopotherincome", {
      _id,
    });
    if (resp.status === 200) {
      const resp1 = await axios.post(url + "/admin/fetchclaculateData", {
        smemberid,
      });
      if (resp1.status === 200) {
        setData(resp1.data.data);
      }
    }
  };
  const action = (data) => {
    return (
      <>
        <Button label="Stop" size="small" onClick={() => makeStop(data._id)} />
      </>
    );
  };
  return (
    <div className="weeklystop p-2">
      <div className="grid">
        <div className="col-12 md:col-4 lg-col-6">
          <Card>
            <div className="form-group flex flex-column gap-3">
              <InputText
                placeholder="Start Member Id"
                value={smemberid}
                disabled={pro === 0 ? false : true}
                onChange={(e) => setSMemberId(e.target.value)}
              />
              <InputText
                placeholder="End Member Id"
                value={ememberid}
                disabled={pro === 0 ? false : true}
                onChange={(e) => setEMemberId(e.target.value)}
              />
              <div className="flex justify-content-between">
                {pro === 0 ? (
                  <Button label="Update" onClick={() => claculateData()} />
                ) : (
                  <Button label="Updatting..." />
                )}
                {pro === 0 ? (
                  <Button
                    label="Fetch Data"
                    onClick={() => fetchclaculateData()}
                  />
                ) : (
                  <Button label="Fetching..." />
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className="col-12 md:col-4 lg-col-6">
          <Card className="head flex flex-column mb-3">
            <div className="flex justify-content-between bg-primary border-round p-2 mb-2">
              <div>Member Id: </div>
              <div>{memId}</div>
            </div>
            <div className="flex justify-content-between bg-primary border-round p-2 mb-2">
              <div>Tot Invest: </div>
              <div>{(totInvest * 1).toFixed(2)}</div>
            </div>
            <div className="flex justify-content-between bg-primary border-round p-2 mb-2">
              <div>Total Income: </div>
              <div>{(totIncome * 1).toFixed(2)}</div>
            </div>
            <div className="flex justify-content-between bg-primary border-round p-2 mb-2">
              <div>Withdraw: </div>
              <div>{(withdraw * 1).toFixed(2)}</div>
            </div>
            <div className="flex justify-content-between bg-primary border-round p-2 mb-2">
              <div>Reward: </div>
              <div>{(reward * 1).toFixed(2)}</div>
            </div>
          </Card>
          <div className="details">
            <Card className="card">
              <DataTable value={data}>
                <Column body={custDate} header="Date"></Column>
                <Column body={custDate1} header="Last Paid"></Column>
                <Column field="pool_amt" header="Amount"></Column>
                <Column field="stop_pay" header="is_Stoped"></Column>
                <Column body={action} header="Action"></Column>
              </DataTable>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
