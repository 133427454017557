import React, { useRef, useState } from "react";
import "./login.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
export const Login = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const handleSubmit = () => {
    setButtonDisabled(true);
    const fetchData = async () => {
      if(window.ethereum){
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0]
        const resp = await axios.post(url + "/checkuser", {
          wa,
        });
        if (resp.status === 200) {
          localStorage.setItem("mxactiveuser", wa);
          Navigate("/user");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "User not registerd. Please register first.",
          });
        }
        setButtonDisabled(false);
      }
    };
    fetchData();
  };
  const checkuser = async () =>{
    if(window.ethereum){
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0]
      const resp = await axios.post(url + "/checkdata", {
        wa,
      });
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Data updated. Proceed to login",
        });
        // localStorage.setItem("mxactiveuser", wa);
        // Navigate("/user");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "User not registerd. Please register first.",
        });
      }
      setButtonDisabled(false);
    }
  }
  return (
    <div className="login">
      <Toast ref={toast} />
      <Card>
        <div className="imgcont">
          <img src="/favicon.png" width={70} alt="" />
          <img src="/misoarx.png" alt="" />
        </div>
        <div>100% Automated, Robotic, Self Executed Platform</div>
        <div>
          misoarX is rapidly growing community development Smart Contract based
          automated platform provider. We believe in transparency of
          transaction. misoarX works with Soarx Smart Chain. The plan of this
          platform is designed by world’s highly professional and experienced
          people. Plan of this portal is fully calculated.
        </div>
        {isButtonDisabled === false ? (
          <Button
            label="Auto Login"
            type="button"
            onClick={() => handleSubmit()}
          />
        ) : (
          <Button
            type="button"
            label="Processing..."
            icon="pi pi-spin pi-spinner"
          />
        )}
        <Button severity="warning" label="Check User" className="ml-5" onClick={()=> checkuser()} />
      </Card>
    </div>
  );
};
