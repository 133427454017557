import React, { useEffect, useRef, useState } from "react";
import "./dailygold.scss";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as Yup from "yup";
export const DailyGold = () => {
    const toast = useRef(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [totSilver, setTotalSilver] = useState(0)
    const [products, setProducts] = useState([]);
    const url = process.env.REACT_APP_HOST_ADDR;
    const validationSchema = Yup.object({
      amt: Yup.number("Invalid Amount Found").required("Amount can not be blank"),
    });
    const formik = useFormik({
      initialValues: {
        amt: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const { amt } = values;
        setButtonDisabled(true);
        const resp = await axios.post(url + "/admin/setgold", {
          amt,
        });
        if (resp.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Disbursh Mode Set Successfully.",
          });
          setButtonDisabled(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Disbursh Mode Set Successfully.",
          });
          setButtonDisabled(false);
        }
      },
    });
    useEffect(() => {
      const fetchData = async () => {
        const resp = await axios.get(url + "/admin/getgold");
        setProducts(resp.data.gold);
        setTotalSilver(resp.data.totgold)
      };
      fetchData();
    }, []);
    const isFormFieldValid = (name) =>
      !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
      return (
        isFormFieldValid(name) && (
          <small className="p-error">{formik.errors[name]}</small>
        )
      );
    };
  return (
    <div className="superplatinum">
      <Toast ref={toast} />
      <Card>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div>Total GOLD: {totSilver.toFixed(0)}</div>
              <div>
                <InputText
                  type="text"
                  name="amt"
                  className="p-inputtext-sm"
                  placeholder="Gold Amt"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.amt}
                />
                {getFormErrorMessage("amt")}
              </div>
              <div>
                {isButtonDisabled === false ? (
                  <Button type="submit" label="Submit" />
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    icon="pi pi-spin pi-spinner"
                    label="Submitting..."
                  />
                )}
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Card>
      <div className="card" style={{ marginTop: 5 }}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Member Id</TableCell>
            <TableCell align="left">Paid Times</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row, index) => (
            <TableRow
              key={row.memberid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                {index*1+1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.memberid}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
    </div>
  )
}
