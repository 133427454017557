import React from "react";
import { Card } from "primereact/card";
import "./adminfooter.scss"
import {Link, useNavigate} from "react-router-dom";
export const AdminFooter = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className="adminfooter">
      <Card>
        <div className="cont">
          <Link to="/admin/">
            <img src="/icons/home.webp" alt="" />
            <div>HOME</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/dailyweekly">
            <img src="/icons/singleid.webp" alt="" />
            <div>WEEKLY</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/dailylevel">
            <img src="/icons/singleid.webp" alt="" />
            <div>LEVEL</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/roi">
            <img src="/icons/othertopup.webp" alt="" />
            <div>ROI</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/superplatinum">
            <img src="/icons/incomdetail.webp" alt="" />
            <div>SUP-PLT</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/gold">
            <img src="/icons/withdraw.webp" alt="" />
            <div>GOLD</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/supergold">
            <img src="/icons/withdraw.webp" alt="" />
            <div>SUP-GOLD</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/reward">
            <img src="/icons/withdraw.webp" alt="" />
            <div>REWARD</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/silver">
            <img src="/icons/withdraw.webp" alt="" />
            <div>SILVER</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/platinum">
            <img src="/icons/withdraw.webp" alt="" />
            <div>PLATINUM</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/admin/gbot">
            <img src="/icons/withdraw.webp" alt="" />
            <div>G-BOT</div>
          </Link>
        </div>
      </Card>
    </div>
      {/* <div className="card my-dock">
        <div
          className="dock-window"
          style={{
            position: "fixed",
            left: "50%",
            bottom: "10px",
          }}
        > */}
          
          {/* <Tooltip className="dark-tooltip" target=".p-dock-action" my="center+15 bottom-15" at="center top" showDelay={150} /> */}
          {/* <Dock model={items} position="bottom" /> */}
        {/* </div> */}
      {/* </div> */}
      {/* <UserSpeedDial /> */}
    </>
  )
}
