import React, { useState } from "react";
import "./checkinvest.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment"
export const CheckInvest = () => {
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [tempdata, setTempData] = useState([]);
  const [tempsingledata, setTempSingleData] = useState([]);
  const url = process.env.REACT_APP_HOST_ADDR;
  const fetchData = async () => {
    const resp = await axios.post(url + "/admin/fetchinvest", { date });
    setData(Array.from(resp.data.data));
    console.log("first")
  };
  const getDetails = async (memid) => {
    const resp = await axios.post(url + "/admin/fetchdetails", { memid, date });
    setTempData(Array.from(resp.data.tempTopUp));
    setTempSingleData(Array.from(resp.data.tempSingle));
  };
  const accept = async(uuid)=>{
    const resp = await axios.post(url + "/admin/accepttran", {uuid });
    if(resp.status===200){
        await fetchData()
    }
    
  }
  const update = async (uuid) =>{
    var amt = document.getElementById("_y" + uuid).value
    var jo = document.getElementById("_z" + uuid).value
    const resp = await axios.post(url + "/admin/updateamt", {date, uuid, amt, jo });
    if(resp.status===200){
        await fetchData()
    }
  }
  const getJoinUnder = async (memid) =>{
    const resp = await axios.post(url + "/admin/getjoinunder", {memid });
    alert(resp.data.jo)
  }
  return (
    <div className="checkinvest">
      <div className="grid">
        <div className="col-12">
          <div className="form-group">
            <div className="cont">
              <div>Check Date</div>
              <InputText
                type="date"
                name="trandate"
                className="p-inputtext-sm"
                autoFocus
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
              <Button label="Fetch Data" onClick={() => fetchData()} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <TableContainer component={Paper} sx={{ height: 250 }}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Member Id</TableCell>
                  <TableCell align="left">INVEST</TableCell>
                  <TableCell align="left">DISPLAY</TableCell>
                  <TableCell align="left">ACCEPT</TableCell>
                  <TableCell align="left">JO</TableCell>
                  <TableCell align="left">VALUE</TableCell>
                  <TableCell align="left">UPDATE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row) => (
                    <TableRow
                      key={row._id.toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        {moment(row.tran_date).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.memberid}
                      </TableCell>
                      <TableCell align="left">{row.pool_amt}</TableCell>
                      <TableCell align="left">
                        <Button
                          label="Details"
                          size="small"
                          onClick={() => getDetails(row.memberid)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          label="Accept"
                          severity="success"
                          size="small"
                          onClick={() => accept(row._id.toString())}
                        />
                      </TableCell>
                      <TableCell><InputText size="small" id={"_z"+row._id.toString()} /></TableCell>
                      <TableCell><InputText size="small" id={"_y"+row._id.toString()} /></TableCell>
                      <TableCell align="left">
                        <Button
                          label="Update"
                          severity="danger"
                          size="small"
                          onClick={() => update((row._id).toString())}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="grid">
        <div className="col-6">
            <div>Self Topup</div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>MEM</TableCell>
                  <TableCell align="left">SOARX</TableCell>
                  <TableCell align="left">USDT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempdata &&
                  tempdata.map((row) => (
                    <TableRow
                      key={row._id.toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Button label={row.memberid} onClick={()=>getJoinUnder(row.memberid)} />
                      </TableCell>
                      <TableCell align="left">{row.soarx}</TableCell>
                      <TableCell align="left">{row.usdt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col-6">
        <div>New Id</div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>SPO</TableCell>
                  <TableCell>NEW ADD</TableCell>
                  <TableCell align="left">SOARX</TableCell>
                  <TableCell align="left">USDT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempsingledata &&
                  tempsingledata.map((row) => (
                    <TableRow
                      key={row._id.toString()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.sponsor_id}
                      </TableCell>
                      <TableCell align="left">{row.new_address}</TableCell>
                      <TableCell align="left">{row.soarx}</TableCell>
                      
                      <TableCell align="left">{row.usdt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
