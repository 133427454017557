import React, { useEffect, useRef, useState } from "react";
import "./businesscheck.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import axios from "axios";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
export const BusinessCheck = () => {
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [first, setFirst] = useState(false);
  const [memid, setMemid] = useState("");
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const calculate = async () => {
    setLoading(1);
    await axios
      .post(url + "/admin/calcbusiness", {
        startdate,
        enddate,
        first,
      })
      .then(function (resp) {
        if (resp.status === 200) {
          setLoading(0);
        }
      }).catch(function(error){
        console.log(error)
      }).finally(function(){
        setLoading(0);
      })
  };
  const fetchResult = async () => {
    const resp = await axios.post(url + "/admin/fetchbusiness", {
      memid,
    });
    setData(resp.data.data.currentbusiness);
  };
  return (
    <div className="businesscheck">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-4 left">
          <Card>
            <div className="form-group">
              <div className="cont">
                <div>Start Date</div>
                <InputText
                  type="date"
                  className="p-inputtext-sm"
                  placeholder="Member Id"
                  value={startdate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </div>
              <div className="cont">
                <div>End Date</div>
                <InputText
                  type="date"
                  className="p-inputtext-sm"
                  placeholder="Member Id"
                  value={enddate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
              <div className="flex flex-row align-items-center">
                <Checkbox
                  onChange={(e) => setFirst(e.checked)}
                  checked={first}
                ></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">
                  First Run
                </label>
              </div>
              {loading === 0 ? (
                <Button label="Calculate" onClick={() => calculate()} />
              ) : (
                <Button label="Calculating..." />
              )}
            </div>
          </Card>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <div className="form-group">
                <div className="cont">
                  <div>Member Id</div>
                  <InputText
                    type="number"
                    className="p-inputtext-sm"
                    placeholder="Member Id"
                    value={memid}
                    onChange={(event) => setMemid(event.target.value)}
                  />
                </div>
                <Button label="Fetch Data" onClick={() => fetchResult()} />
                <div className="val">Value: {data}</div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
