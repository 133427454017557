import React, { useRef, useState } from "react";
import "./underprocess.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
export const UnderProcess = () => {
    const toast = useRef(null);
  return (
    <div className="underprocess">
    <Toast ref={toast} />
    <Card>
      <div className="imgcont">
        <img src="/favicon.png" width={70} alt="" />
        <img src="/misoarx.png" alt="" />
      </div>
      <div>100% Automated, Robotic, Self Executed Platform</div>
      <div>
       <h3>Synchronizing...</h3>
       <h5>Stay Connected with Us, We will be back soon.</h5>
      </div>
    </Card>
  </div>
  )
}
