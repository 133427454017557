import React, { useEffect, useState } from "react";
import axios from "axios";
export const DbBusiness = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState({});
  const [level, setLevel] = useState(0)
  const [reward, setReward] = useState("0.00")
  useEffect(() => {
    const fetchData = async () => {
      const wa = await getAddress();
      const resp = await axios.post(url + "/user/fetchincomes", {
        wa,
      });
      const rewardresp = await axios.post(url + "/user/fetchindincomes",{
        wa,
        inc:'REWARD'
      })
      setData(resp.data);
      if(rewardresp.data.indIncome[0]){
        setReward(rewardresp.data.indIncome[0].sum)
      }
      
      //console.log(rewardresp.data.indIncome[0].sum)
    };
    fetchData();
  }, []);
  async function getAddress() {
    if (window.ethereum) {
      const x = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      return x[0];
    } else {
      console.log("Second");
    }
  }
  return (
    <div className="dbbusiness">
      <div className="grid">
        <div className="col-3 md:col-3 lg:col-3">
          <div className="text-center p-3 cust-bg">
          <img src="/icons/invest.webp" width={40} alt="" />
            <div>Total Invest</div>
            <div>{(data.totinvest)}</div>
          </div>
        </div>
        <div className="col-3 md:col-3 lg:col-3">
          <div className="text-center p-3 cust-bg">
          <img src="/icons/income.webp" width={40} alt="" />
            <div>Total Income</div>
            <div>{data.gTotIncome + data.totLevel - reward}</div>
          </div>
        </div>
        <div className="col-3 md:col-3 lg:col-3">
          <div className="text-center p-3 cust-bg">
          <img src="/icons/valid.webp" width={40} alt="" />
            <div>Valid Upto</div>
            <div>{(data.totinvest && data.totinvest*4)}</div>
          </div>
        </div>
        <div className="col-3 md:col-3 lg:col-3">
          <div className="text-center p-3 cust-bg">
          <img src="/icons/remain.webp" width={40} alt="" />
            <div>Remain</div>
            <div>{data.totinvest && (data.totinvest*4 - (data.gTotIncome + data.totLevel - reward)).toFixed(3)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
