import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import "./addresschange.scss";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
export const AddressChange = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isCButtonDisabled, setCButtonDisabled] = useState(false);
  const validationSchema = Yup.object({
    memberid: Yup.number("Invalid value found.")
      .required("This field is required.")
  });
  const formik = useFormik({
    initialValues: {
      memberid: "",
      newaddress: "",
    },
    validationSchema,
    onSubmit: async (values) => {
        const resp = await axios.post(url + "/admin/addresschange",{values})
        alert(resp.status)
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="addresschange">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>
                      <div className="avail">Member Id</div>
                      <div>
                        <InputText
                          type="number"
                          name="memberid"
                          className="p-inputtext-sm second"
                          placeholder="Member Id"
                          onChange={formik.handleChange}
                          value={formik.values.memberid}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="avail">New Address</div>
                      <div>
                        <InputText
                          type="text"
                          name="newaddress"
                          className="p-inputtext-sm second"
                          placeholder="New Address"
                          onChange={formik.handleChange}
                          value={formik.values.newaddress}
                        />
                      </div>
                      {getFormErrorMessage("newaddress")}
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" label="Submit" />
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        label="Submitting..."
                        icon="pi pi-spin pi-spinner"
                      />
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
