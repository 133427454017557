// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .userappbar {
  padding: 10px 10px;
  background-color: rgb(147, 195, 220);
}
.theme-light .userappbar .p-menubar {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.theme-dark .userappbar {
  padding: 10px 10px;
  background-color: #1e3948;
}
.theme-dark .userappbar .p-menubar {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/style.scss","webpack://./src/components/usercomponent/userappbar/userappbar.scss"],"names":[],"mappings":"AAqBI;EClBA,kBAAA;EACA,oCAAA;AADJ;AAEI;EACI,oCAAA;EACA,0CAAA;EACA,sBAAA;EACA,qBAAA;AAAR;ADYI;EClBA,kBAAA;EACA,yBAAA;AASJ;AARI;EACI,oCAAA;EACA,0CAAA;EACA,sBAAA;EACA,qBAAA;AAUR","sourcesContent":["$themes: (\n  light: (\n    navDefault: rgb(157, 186, 200),\n    bg:rgb(147, 195, 220),\n    cardLight:white,\n    textColor: #000,\n    textContra:#512727,\n    linkText:rgb(61, 61, 138)\n  ),\n  dark: (\n    navDefault: #1e3948,\n    bg: #1e3948,\n    cardLight:#1f2c33,\n    textColor: whitesmoke,\n    textContra:#f5ff84,\n    linkText:aqua\n  ),\n);\n\n@mixin themify($themes) {\n  @each $theme, $map in $themes {\n    .theme-#{$theme} & {\n      $theme-map: () !global;\n      @each $key, $submap in $map {\n        $value: map-get(map-get($themes, $theme), \"#{$key}\");\n        $theme-map: map-merge(\n          $theme-map,\n          (\n            $key: $value,\n          )\n        ) !global;\n      }\n      @content;\n      $theme-map: null !global;\n    }\n  }\n}\n\n@function themed($key) {\n  @return map-get($theme-map, $key);\n}\n\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n@mixin tablet {\n  @media (max-width: 960px) {\n    @content;\n  }\n}","@import \"../../../style.scss\";\n.userappbar {\n  @include themify($themes) {\n    padding: 10px 10px;\n    background-color: themed(\"bg\");\n    .p-menubar {\n        background: rgba(255, 255, 255, 0.1);\n        border: 1px solid rgba(255, 255, 255, 0.2);\n        padding: 0.5rem 0.5rem;\n        border-radius: 0.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
