import React, { useContext } from "react";
import { InputSwitch } from "primereact/inputswitch";
import "./homeappbar.scss";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../../context/darkModeContext";
import { Button } from "primereact/button";
export const HomeAppbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const items = [
    {
      url: "/",
      label: "Home",
      icon: "pi pi-home",
    },
  ];
  const start = (
    <img
      alt="logo"
      src="/favicon.png"
      height="40"
      className="mr-2"
    ></img>
  );
  const end = (
    <div className="flex align-items-center gap-2">
      {darkMode ? (
        <InputSwitch checked={true} onClick={toggle} />
      ) : (
        <InputSwitch onClick={toggle} />
      )}
      <Button
        label="Log In"
        icon="pi pi-users"
        severity="info"
        onClick={() => (window.location.href = "/login")}
      />
      <Button
        label="Register"
        icon="pi pi-user-plus"
        onClick={() => (window.location.href = "/register/1")}
      />
    </div>
  );
  return (
    <div>
      <div className="card homeappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
