import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { ethers } from "ethers";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import "./topup.scss";
import abi from "../../../ABI.json";
export const TopUp = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    reqsoarx: "0",
    gasfee: "0",
    totalsoarx: "0",
    oneusdt: 0,
  });
  const [totsoarx, setTotsoarx] = useState(0);
  const validationSchema = Yup.object({
    investorid: Yup.number("Invalid Investor Id").required(
      "Top-Up Id cant not be blank."
    ),
    usdt: Yup.number("Invalid value found.")
      .required("This field is required.")
      .min(20, "Minimum 20 USDT is required."),
    soarx: Yup.number("Invalid value found.").required(
      "This field is required."
    ),
  });
  const formik = useFormik({
    initialValues: {
      avlsoarx: "",
      investorid: "",
      investsoarx: "",
      reqsoarx: 0,
      soarx: 0,
      usdt: 20,
    },
    validationSchema,
    onSubmit: async (values) => {
      let srx = values.usdt*(data.oneusdt)
      if(srx*1 > balance*1){
        show("error", "Error", "Something Went wrong! Try after sometimes");
        return false
      }
      setButtonDisabled(2);
      var rnd = Math.floor(100000 + Math.random() * 900000);
      const _address = address;
      const _balance = balance;
      const _investorid = values.investorid;
      const _soarx = srx;
      const _usdt = values.usdt;
      if(_soarx*1<=0){
        show("error", "Error", "Something Went wrong! Try after sometimes")
        return false
      }
      axios
        .post(url + "/user/temptopup", {
          rnd,
          _address,
          _balance,
          _investorid,
          _soarx,
          _usdt,
        })
        .then(async function (resp) {
          if (resp.status === 200) {
            let tranId = rnd*1
            var soar = _soarx*1 + data.gasfee*1
            soar = soar.toString()
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            var mycontract = new ethers.Contract(contract, abi, signer);
            mycontract.memberstack(tranId, _address, _investorid, 0, {value:ethers.utils.parseUnits(soar,"ether")}).then(function(done){
              axios.post(url + "/user/confirmtopup",{
                tranId,
                _investorid,
                _usdt
              }).then(function(response){
                show("success", "Success","Staking Sucessfull");
                setVisible(true)
                setButtonDisabled(2);
                formik.setFieldValue("investorid","")
                formik.setFieldValue("soarx","")
                formik.setFieldValue("usdt","")
              })
          
            })
          } else {
            show("error", "Error", "Something Went wrong! Try after sometimes");
          }
        }).error(function(error){
          show("error", "Error", "Something Went wrong! Try after sometimes");
        })
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const show = (x, title, msg) => {
    toast.current.show({ severity: x, summary: title, detail: msg });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var bal = await provider.getBalance(wa);
        bal = ethers.utils.formatEther(bal);
        var x = await reqSoarx(20);
        setData({
          reqsoarx: x.reqsrx,
          gasfee: x.gasFee,
          totalsoarx: x.total,
          oneusdt: x.oneusdt,
        });
        setAddress(wa)
        setBalance(bal)
        setTotsoarx(x.total);
        formik.setFieldValue("reqsoarx", x.total);
        formik.setFieldValue("soarx", x.reqsrx);
        if (bal * 1 < x.total * 1) {
          show(
            "error",
            "Error",
            "You do not have sufficient balance to topup."
          );
        }else{
          setButtonDisabled(1);
        }
      } else {
        console.log("Second");
      }
    };
    fetchData();
  }, []);
  const reqSoarx = async (usdt) => {
    const resp = await axios.get("https://api.coindcx.com/exchange/ticker");
    var mydata = resp.data;
    var usdtinr = mydata.filter(function (entry) {
      return entry.market === "USDTINR";
    });
    // const soarxprice = await axios.get(
    //   "https://api.soarxbits.com/ticker/tickers/SOARXINR"
    // );
    var srxprice = 0.52;
    var usdtprice = 90;
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    var oneusdt = (usdtprice / srxprice) * 1;
    gas = gas.toFixed(0);
    return {
      reqsrx: reqSoar,
      gasFee: gas,
      total: reqSoar * 1 + gas * 1,
      oneusdt: oneusdt,
    };
  };
  const usdtChanged = (usdtval) => {
    formik.setFieldValue("usdt", usdtval);
    formik.setFieldValue("soarx", (data.oneusdt * usdtval).toFixed(0));
    setTotsoarx((data.oneusdt * usdtval).toFixed(0) * 1 + data.gasfee * 1);
  };
  const soarxChanged = (soarx) => {
    formik.setFieldValue("soarx", soarx);
    formik.setFieldValue("usdt", parseInt((1 / data.oneusdt) * soarx));
    setTotsoarx(soarx * 1 + data.gasfee * 1);
  };
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Success</span>
    </div>
  );

  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  return (
    <div className="topup">
      <Toast ref={toast} />
      <div className="grid">
      <div className="col-12 md:col-6 lg:col-6 left">
      <Card title="Self/Other Id Top-Up">
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          Minimum soarX is: {data.reqsoarx}, and {data.gasfee} SOARX for network
          fee.
        </Alert>
        <Alert sx={{ marginBottom: 1 }}>Available SOARX: {(balance*1).toFixed(2)}</Alert>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div>
                <div>TOPUP ID</div>
                <InputText
                  type="text"
                  name="investorid"
                  className="p-inputtext-sm"
                  placeholder="Topup Id"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.investorid}
                />
                {getFormErrorMessage("investorid")}
              </div>
              <div>
                <div>INVESTING SOARX</div>
                <div className="srxusdt">
                  <InputText
                    type="number"
                    name="soarx"
                    className="p-inputtext-sm first"
                    placeholder="SOARX"
                    onChange={(e) => soarxChanged(e.target.value)}
                    value={formik.values.soarx}
                  />
                  <InputText
                    type="number"
                    name="usdt"
                    className="p-inputtext-sm second"
                    placeholder="USDT"
                    onChange={(e) => usdtChanged(e.target.value)}
                    value={formik.values.usdt}
                  />
                </div>
                {getFormErrorMessage("usdt")}
                {getFormErrorMessage("soarx")}
              </div>
            </div>
            <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
              Total {totsoarx} will be deducted from your wallet.
            </Alert>
            {isButtonDisabled===0?
            <Button type="button" label="Loading" />
            :
            <>
            {isButtonDisabled === 1 ? (
              <Button type="submit">Submit</Button>
            ) : (
              <Button
                type="button"
                variant="outlined"
                icon="pi pi-spin pi-spinner"
              >
                {" "}
                Submitting..
              </Button>
            )}
            </>
            }
            
          </Form>
        </FormikProvider>
      </Card>
      </div>
      <div className="col-12 md:col-6 lg:col-6 left">
        <Card>
          Topup List


          {/* <Link to="/user/fetchtopup">
          <Button label="Fetch Topup" />
          </Link> */}
        </Card>
      </div>
      </div>
      
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          Congratulation Your Topup is Successfull.
        </p>
      </Dialog>
    </div>
  );
};
