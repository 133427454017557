import React, { useRef, useState } from "react";
import "./tdsreport.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import * as Yup from "yup";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
export const TdsReport = () => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    startdate: Yup.date().required(
      "Cant not be blank."
    ),
    enddate: Yup.date().required(
      "Cant not be blank."
    ),
  });
  const formik = useFormik({
    initialValues: {
        startdate: "",
      enddate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { username, password } = values;
      setButtonDisabled(true);
      const resp = await axios.post(url + "/admin/gettdsreport", {
        values
      });
      if (resp.status === 201) {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: "User or Password not matched.",
        // });
        setButtonDisabled(false);
      } else if (resp.status === 200) {
        setButtonDisabled(false);
        // Navigate("/admin/");
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="tdsreport">
      <div className="grid left">
        <Card>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="form-group">
                  <div>USER NAME</div>
                  <InputText
                    type="date"
                    name="startdate"
                    className="p-inputtext-sm"
                    placeholder="User Name"
                    autoFocus
                    onChange={formik.handleChange}
                    value={formik.values.startdate}
                  />
                  {getFormErrorMessage("startdate")}
                  <div>PASSWORD</div>
                  <InputText
                    name="enddate"
                    type="date"
                    className="p-inputtext-sm"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.enddate}
                  />
                  {getFormErrorMessage("enddate")}
                {isButtonDisabled === false ? (
                  <Button type="submit" size="small" label="Submit" />
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    icon="pi pi-spin pi-spinner"
                    label="Submitting..."
                  />
                )}
              </div>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </div>
  );
};
