import React, { useEffect, useRef, useState } from "react";
import "./register.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { ethers } from "ethers";
import CircularProgress from "@mui/material/CircularProgress";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
export const Register = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const toast = useRef(null);
  let { sponserid } = useParams();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [data, setDate] = useState({
    reqsoarx: "0",
    gasfee: "0",
    totalsoarx: "0",
  });
  const show = (x, title, msg) => {
    toast.current.show({ severity: x, summary: title, detail: msg });
  };
  const validationSchema = Yup.object({
    spoid: Yup.number("Invalid Sponsored Id").required(
      "Sponsored Id cant not be blank."
    ),
    minusdt: Yup.number("Invalid Amount")
      .required("Sponsored Id cant not be blank.")
      .min(30, "Minimum USDT is 30"),
  });
  const formik = useFormik({
    initialValues: {
      spoid: sponserid,
      minusdt: 30,
      availsoarx: balance,
      reqsoarx: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      
    //   axios
    //     .post("apiurl" + "/auth/login", {})
    //     .then(function (response) {
    //       if (response.status === 200) {
    //         var in30Minutes = new Date(new Date().getTime() + 50 * 60 * 1000);
    //         Cookies.set("token", response.data.token, {
    //           expires: in30Minutes,
    //         });
    //         localStorage.setItem("srxactiveemail", formik.values.email);
    //         //Navigate("/user");
    //       }
    //       // if (response.status == 201) {
    //       //   Navigate("/displaymsg/Success/Account Created Successfully. Please Proceed to Login/login/1");
    //       // }
    //     })
    //     .catch(function (error) {
    //       //notify("Userid or password not matched!");
    //       //setButtonDisabled(false);
    //       console.log(error.response.data.status);
    //     });
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        // const resp = await axios.post(url + "/checkdata",{wa})
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var bal = await provider.getBalance(wa);
        bal = ethers.utils.formatEther(bal);
        var x = await reqSoarx(30);
        setDate({ reqsoarx: x.reqsrx, gasfee: x.gasFee, totalsoarx: x.total });
        formik.setFieldValue("reqsoarx", x.total);
        if (formik.values.availsoarx * 1 < x.total * 1) {
          show("error", "Error", "You do not have sufficient balance to register.");
        }
      }

    };
    fetchData();
  }, []);
  const reqSoarx = async (usdt) => {
    const resp = await axios.get("https://api.coindcx.com/exchange/ticker");
    var mydata = resp.data;
    var usdtinr = mydata.filter(function (entry) {
      return entry.market === "USDTINR";
    });
    const soarxprice = await axios.get(
      "https://api.soarxbits.com/ticker/tickers/SOARXINR"
    );
    var srxprice = soarxprice.data.ticker.high;
    var usdtprice = usdtinr[0].last_price;
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    gas = gas.toFixed(0);
    return { reqsrx: reqSoar, gasFee: gas, total: reqSoar * 1 + gas * 1 };
  };
  const getbalance = (address) => {
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        setBalance(ethers.utils.formatEther(balance));
        formik.setFieldValue(
          "availsoarx",
          parseInt(ethers.utils.formatEther(balance))
        );
      });
  };
  const accountChangeHandler = (account) => {
    setAddress(account);
    getbalance(account);
  };
  return (
    <div className="newregistration">
      <Toast ref={toast} />
      <Card title="New Registration">
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Please check sponsor id before procceding...
        </Alert>
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Extra {data.gasfee} soar coin will be for Gas Fee
        </Alert>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div>
                <div>SPONSOR ID</div>
                <InputText
                  type="text"
                  name="spoid"
                  className="p-inputtext-sm"
                  placeholder="SPONSOR ID"
                  readOnly
                  onChange={formik.handleChange}
                  value={formik.values.spoid}
                />
                {getFormErrorMessage("spoid")}
              </div>
              <div>
                <div>MINIMUM USDT</div>
                <InputText
                  name="minusdt"
                  type="number"
                  className="p-inputtext-sm"
                  placeholder="MINIMUM USDT"
                  onChange={formik.handleChange}
                  value={formik.values.minusdt}
                />
                {getFormErrorMessage("minusdt")}
              </div>
              <div>
                <div>AVAILABLE SOARX</div>
                <InputText
                  name="availsoarx"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="AVAILABLE SOARX"
                  onChange={formik.handleChange}
                  value={formik.values.availsoarx}
                />
              </div>
              <div>
                <div>REQUIRE SOARX</div>
                <InputText
                  name="reqsoarx"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="REQUIRE SOARX"
                  onChange={formik.handleChange}
                  value={formik.values.reqsoarx}
                />
              </div>
            </div>
            <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
              Total {data.reqsoarx} + {data.gasfee} will be deducted from your
              wallet.
            </Alert>
            {isButtonDisabled === false ? (
              <Button type="submit">Submit</Button>
            ) : (
              <Button
                type="button"
                variant="outlined"
                icon='pi pi-spin pi-spinner'
              > Submitting..
              </Button>
            )}
          </Form>
        </FormikProvider>
      </Card>
    </div>
  );
};
