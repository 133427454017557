import React, { useEffect, useState } from "react";
import axios from "axios";
import "./incomedetails.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
export const IncomeDetails = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState({});
  const [level, setLevel] = useState(0)
  const [totIncome, setTotalIncome] = useState(0)
  const [withdraw, setWithdraw] = useState(0)
  const [balance, setBalance] = useState(0)
  const [income, setIncome] = useState({
    INVEST:0,
    VM:0,
    AUTO:0,
    WEEKLY:0,
    SILVER:0,
    PLATINUM:0,
    SUP:0,
    DGOLD:0,
    SPGOLD:0,
    REWARD:0,
  });
  useEffect(() => {
    const fetchData = async () => {
      if(window.ethereum){
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0]
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        var bal = await provider.getBalance(wa)
        const resp = await axios.post(url + "/user/fetchincomes", {
          wa,
        });
        setData(resp.data);
        setBalance(resp.data.balance)
        if(!resp.data.incdetail) return
        await updateData(resp.data.incdetail)
        setLevel(resp.data.totLevel)
        setTotalIncome(resp.data.gTotIncome)
        setWithdraw(resp.data.withdraw)
      }
      //const wa = await getAddress();
      
    };
    fetchData();
  }, []);
  async function updateData(xdata){

    for(let i=0; i < xdata.length; i++){
      if(xdata[i]._id=='INVEST'){
        setIncome(prevIncome => ({...prevIncome, INVEST: xdata[i].sum}))
      }else if(xdata[i]._id=='VM'){
        setIncome(prevIncome => ({...prevIncome, VM: xdata[i].sum}))
      }else if(xdata[i]._id=='WEEKLY'){
        setIncome(prevIncome => ({...prevIncome, WEEKLY: xdata[i].sum}))
      }else if(xdata[i]._id=='SILVER'){
        setIncome(prevIncome => ({...prevIncome, SILVER: xdata[i].sum}))
      }else if(xdata[i]._id=='REWARD'){
        setIncome(prevIncome => ({...prevIncome, REWARD: xdata[i].sum}))
      }else if(xdata[i]._id=='SUP'){
        setIncome(prevIncome => ({...prevIncome, SUP: xdata[i].sum}))
      }else if(xdata[i]._id=='DGOLD'){
        setIncome(prevIncome => ({...prevIncome, DGOLD: xdata[i].sum}))
      }else if(xdata[i]._id=='SPGOLD'){
        setIncome(prevIncome => ({...prevIncome, SPGOLD: xdata[i].sum}))
      }else if(xdata[i]._id=='PLATINUM'){
        setIncome(prevIncome => ({...prevIncome, PLATINUM: xdata[i].sum}))
      }
    }
  }
  return (
    <div className="incomedetails">
      <div className="grid">
        <div className="col-12 md:col-4 lg:col-4">
          <div className="p-3 content">
            <div className="head">
              <div>Income Statistics</div>
              <div>Working & Weekly Details</div>
              <Divider />
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/refferalincome.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/1">REFFERAL INCOME</Link></span>
                <span className="f2">10% Direct</span>
              </div>
              <div className="amt">{(income.INVEST).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/vmatrix.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/2">V MATRIX</Link></span>
                <span className="f2">Previous Income</span>
              </div>
              <div className="amt">{(income.VM).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/autopool.webp" alt="" /></div>
              <div className="title">
                <span className="f1">AUTO POOL</span>
                <span className="f2">Non Workingt</span>
              </div>
              <div className="amt">0.00</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/weekly.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/3">WEEKLY</Link></span>
                <span className="f2">0.5 + 1.5 on Invest</span>
              </div>
              <div className="amt">{(income.WEEKLY).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/levelincome.webp" alt="" /></div>
              <div className="title">
                <span className="f1">LEVEL INCOME</span>
                <span className="f2">UPTO 44%</span>
              </div>
              <div className="amt">{level.toFixed(3)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-4 lg:col-4">
          <div className="p-3 content">
            <div className="head">
              <div>Income Statistics</div>
              <div>Working & Weekly Details</div>
              <Divider />
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/silver.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/4">SILVER</Link></span>
                <span className="f2">4% on Global Business</span>
              </div>
              <div className="amt">{(income.SILVER).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/platinum.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/5">PLATINUM</Link></span>
                <span className="f2">Auto Pool</span>
              </div>
              <div className="amt">{(income.PLATINUM).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/platinumslab.webp" alt="" /></div>
              <div className="title">
                <span className="f1">PLATINUM SLAB</span>
                <span className="f2">Auto Pool</span>
              </div>
              <div className="amt">0.00</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/platinumrewads.webp" alt="" /></div>
              <div className="title">
                <span className="f1">PLATINUM REWARD</span>
                <span className="f2">Auto Pool</span>
              </div>
              <div className="amt">0.00</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/superplatinum.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/6">SUPER PLATINUM</Link></span>
                <span className="f2">4% on Global Business</span>
              </div>
              <div className="amt">{(income.SUP).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/gold.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/7">GOLD</Link></span>
                <span className="f2">4% on Global Business</span>
              </div>
              <div className="amt">{(income.DGOLD).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/supergold.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/8">SUPER GOLD</Link></span>
                <span className="f2">4% on Global Business</span>
              </div>
              <div className="amt">{(income.SPGOLD).toFixed(3)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-4 lg:col-4">
          <div className="p-3 content">
            <div className="head">
              <div>Income Statistics</div>
              <div>Working & Weekly Details</div>
              <Divider />
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/reward.webp" alt="" /></div>
              <div className="title">
                <span className="f1"><Link to="/user/userincome/9">REWARD</Link></span>
                <span className="f2">Team Business</span>
              </div>
              <div className="amt">{(income.REWARD).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/total.webp" alt="" /></div>
              <div className="title">
                <span className="f1">TOTAL</span>
                <span className="f2">Check It.</span>
              </div>
              <div className="amt">{(totIncome*1+level*1).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/withdraw.webp" alt="" /></div>
              <div className="title">
                <span className="f1">WITHDRAW</span>
                <span className="f2">See Details</span>
              </div>
              <div className="amt">{(withdraw*1).toFixed(3)}</div>
            </div>
            <div className="cont">
              <div className="ico"><img src="/icons/inwallet.webp" alt="" /></div>
              <div className="title">
                <span className="f1">IN WALLET</span>
                <span className="f2">Have Savings</span>
              </div>
              <div className="amt">{(balance).toFixed(3)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
