import { createBrowserRouter, RouterProvider, Outlet, BrowserRouter } from "react-router-dom";
import "./style.scss";
import { HomeAppbar } from "./components/homecomponent/homappbar/HomeAppbar";
import { HomeFooter } from "./components/homecomponent/homefooter/HomeFooter";
import { Home } from "./pages/home/Home";
import { Register } from "./pages/home/Register";
import { Login } from "./pages/home/Login";
import { DarkModeContext } from "./context/darkModeContext";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import React, { useContext } from "react";
import { UserAppBar } from "./components/usercomponent/userappbar/UserAppBar";
import { UserFooter } from "./components/usercomponent/userfooter/UserFooter";
import { DashBoard } from "./pages/user/dashboard/DashBoard";
import {IncomeDetails} from "./pages/user/incomedetail/IncomeDetails"
import { NewSingleId } from "./pages/user/newsingleid/NewSingleId";
import { Withdraw } from "./pages/user/withdraw/Withdraw";
import { TopUp } from "./pages/user/topup/TopUp";
import { AdminDashboard } from "./pages/admin/dashboard/AdminDashboard";
import { AdminAppBar } from "./components/admincomponent/AdminAppBar";
import { AdminFooter } from "./components/admincomponent/AdminFooter";
import { AdminLogin } from "./pages/home/AdminLogin";
import { DailyLevel } from "./pages/admin/dailylevel/DailyLevel";
import { Reward } from "./pages/admin/reward/Reward";
import { Silver } from "./pages/admin/silver/Silver";
import { Platinum } from "./pages/admin/platinum/Platinum";
import { DailyRoi } from "./pages/admin/dailyroi/DailyRoi";
import { SuperPlatinum } from "./pages/admin/superplatinum/SuperPlatinum";
import { DailyGold } from "./pages/admin/dailygold/DailyGold";
import { SuperGold } from "./pages/admin/supergold/SuperGold";
import { UnderProcess } from "./pages/home/underprocess/UnderProcess";
import { Downline } from "./pages/user/downline/Downline";
import { WeeklyGbot } from "./pages/admin/weeklygbot/WeeklyGbot";
import { DailyWeekly } from "./pages/admin/dailyweekly/DailyWeekly";
import { AddressChange } from "./pages/admin/addresschange/AddressChange";
import { BusinessCheck } from "./pages/admin/businesscheck/BusinessCheck";
import { FetchTopUp } from "./pages/user/fetchtopup/FetchTopUp";
import { StackList } from "./pages/user/stacklist/StackList";
import { UserIncome } from "./pages/user/userincome/UserIncome";
import { SponsorChange } from "./pages/admin/sponsorchange/SponsorChange";
import { OfficeLogin } from "./pages/home/OfficeLogin";
import { OfficeDashboard } from "./pages/office/OfficeDashboard";
import { GstReport } from "./pages/office/GstReport";
import { TdsReport } from "./pages/office/TdsReport";
import { SpGoldCalc } from "./pages/admin/spglodcalc/SpGoldCalc";
import { CheckInvest } from "./pages/admin/checkinvest/CheckInvest";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppbar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <UserAppBar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminAppBar />
        <Outlet />
        <AdminFooter />
      </div>
    );
  };
  const OfficeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminAppBar />
        <Outlet />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/underprocess", element: <UnderProcess /> },
        { path: "/login", element: <Login /> },
        { path: "/register/:sponserid", element: <Register /> },
        { path: "/adminlogin", element: <AdminLogin /> },
        { path: "/officelogin", element: <OfficeLogin /> },
      ]
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <DashBoard /> },
        { path: "/user/incomedetail", element: <IncomeDetails /> },
        { path: "/user/newsingleid", element: <NewSingleId /> },
        { path: "/user/topup", element: <TopUp /> },
        { path: "/user/downline", element: <Downline /> },
        { path: "/user/fetchtopup", element: <FetchTopUp /> },
        { path: "/user/stacklist", element: <StackList /> },
        { path: "/user/userincome/:incid", element: <UserIncome /> },

        { path: "/user/withdraw", element: <Withdraw /> },
      
    ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/", element: <AdminDashboard /> },    
        { path: "/admin/dailylevel", element: <DailyLevel /> },   
        { path: "/admin/dailyweekly", element: <DailyWeekly /> },   
        { path: "/admin/roi", element: <DailyRoi /> },   
        { path: "/admin/superplatinum", element: <SuperPlatinum /> },   
        { path: "/admin/gold", element: <DailyGold /> },   
        { path: "/admin/supergold", element: <SuperGold /> },   
        { path: "/admin/reward", element: <Reward /> }, 
        { path: "/admin/silver", element: <Silver /> }, 
        { path: "/admin/platinum", element: <Platinum /> }, 
        { path: "/admin/gbot", element: <WeeklyGbot /> }, 
        { path: "/admin/addresschange", element: <AddressChange /> }, 
        { path: "/admin/businesscheck", element: <BusinessCheck /> }, 
        { path: "/admin/sponsorchange", element: <SponsorChange /> }, 
        { path: "/admin/spgoldcalc", element: <SpGoldCalc /> }, 
        { path: "/admin/checkinvest", element: <CheckInvest /> }, 
    ],
    },
    {
      path: "/office",
      element: <OfficeLayout />,
      children: [
        { path: "/office/", element: <OfficeDashboard /> },    
        { path: "/office/gstreport", element: <GstReport /> },    
        { path: "/office/tdsreport", element: <TdsReport /> },    
    ],
    },
  ]);
  return (
      <RouterProvider router={router} />
  );
}
export default App;
