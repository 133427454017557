import React, { useRef } from "react";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";
import { Tooltip } from 'primereact/tooltip';
import "./userspeeddial.scss"
export const UserSpeedDial = () => {
  const toast = useRef(null);
  const items = [
    {
      label: "Single Id",
      icon: "bgsingleid",
      command: () => {
        window.location.href = "/user/newsingleid";
      },
    },
    {
      label: "Other Topup",
      icon: "bgothertopup",
      command: () => {
        window.location.href = "/user/otherstopup";
      },
    },
    {
      label: "Self TopUp",
      icon: "bgselftopup",
      command: () => {
        window.location.href = "/user/selftopup";
      },
    },
    {
      label: "Withdraw",
      icon: "bgwithdraw",
      command: () => {
        window.location.href = "/user/withdraw";
      },
    },
  ];
  return (
    <div className="userspeeddial">
      <Toast ref={toast} />
      <Tooltip target=".p-speeddial-action" position="left" />
      <SpeedDial
        model={items}
        radius={80}
        showIcon="pi pi-bars"
        hideIcon="pi pi-times"
        type="semi-circle"
        direction="left"
        style={{ top: "calc(50% - 2rem)", right: 5 }}
      />
    </div>
  );
};
