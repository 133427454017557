import "./dashboard.scss";
import { DbWelcome } from "../../../components/usercomponent/DbWelcome";
import { DbBusiness } from "../../../components/usercomponent/DbBusiness";
export const DashBoard = () => {
  return (
    <div className="dashboard">
      <DbWelcome />
    </div>
  );
};
