import React, { useEffect, useRef, useState } from "react";
import "./soarxdeposit.scss";
// import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
// import * as Yup from "yup";
// import { ethers } from "ethers";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import { Alert } from "@mui/material";
import { Toast } from "primereact/toast";
// import { Dialog } from "primereact/dialog";
// import abi from "../../../ABI.json";
export const SoarxDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  // const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [address, setAddress] = useState("");
  // const [balance, setBalance] = useState(0);
  const [withamt, setWithAmt] = useState("0");
  const [deposits, setDeposits] = useState({
    totDepost: 0,
    totWithdraw: 0,
    allowwithdraw: 0,
  });
  // const [data, setData] = useState({
  //   reqsoarx: "0",
  //   gasfee: "",
  //   totalsoarx: "0",
  //   oneusdt: 0,
  // });
  // const [totsoarx, setTotsoarx] = useState(0);
  // const validationSchema = Yup.object({
  //   usdt: Yup.number("Invalid value found.")
  //     .required("This field is required.")
  //     .min(10, "Minimum 30 USDT is required."),
  //   soarx: Yup.number("Invalid value found.").required(
  //     "This field is required."
  //   ),
  // });
  // const formik = useFormik({
  //   initialValues: {
  //     avlsoarx: 0,
  //     investsoarx: 0,
  //     reqsoarx: 0,
  //     gasfee: 0,
  //     totsoarx: 0,
  //     soarx: 0,
  //     usdt: 30,
  //   },
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     let srx = values.usdt * data.oneusdt;
  //     if (srx * 1 > balance * 1) {
  //       show("error", "Error", "Something Went wrong! Try after sometimes");
  //       return false;
  //     }
  //     setButtonDisabled(2);
  //     var rnd = Math.floor(100000 + Math.random() * 900000);
  //     const _address = address;
  //     const _balance = balance;
  //     const _soarx = srx;
  //     const _usdt = values.usdt;
  //     if (_soarx * 1 <= 0) {
  //       show("error", "Error", "Something Went wrong! Try after sometimes");
  //       return false;
  //     }
  //     axios
  //       .post(url + "/user/tempdeposit", {
  //         rnd,
  //         _address,
  //         _soarx,
  //         _usdt,
  //       })
  //       .then(async function (resp) {
  //         if (resp.status === 200) {
  //           let tranId = rnd * 1;
  //           var soar = _soarx * 1 + data.gasfee * 1;
  //           soar = soar.toString();
  //           const provider = new ethers.providers.Web3Provider(window.ethereum);
  //           await provider.send("eth_requestAccounts", []);
  //           const signer = provider.getSigner();
  //           var mycontract = new ethers.Contract(contract, abi, signer);
  //           try {
  //             mycontract
  //               .contribute({ value: ethers.utils.parseUnits(soar, "ether") })
  //               .then(function (done) {
  //                 axios
  //                   .post(url + "/user/confdeposit", {
  //                     tranId,
  //                     _address,
  //                     _soarx,
  //                     _usdt,
  //                   })
  //                   .then(function (resp) {
  //                     setButtonDisabled(false);
  //                     formik.values.avlusdt = parseInt(0);
  //                     formik.values.withdraw = parseInt(0);

  //                   });
  //               })
  //               .catch(function (error) {
  //                 setButtonDisabled(false);
  //               });
  //           } catch (error) {
  //             setButtonDisabled(false);
  //           }
  //         } else {
  //           show("error", "Error", resp.data.message);
  //           setButtonDisabled(1);
  //         }
  //       })
  //       .error(function (error) {
  //         show("error", "Error", "Something Went wrong! Try after sometimes2");
  //       });
  //     //setButtonDisabled(true);
  //   },
  // });
  // const isFormFieldValid = (name) =>
  //   !!(formik.touched[name] && formik.errors[name]);
  // const getFormErrorMessage = (name) => {
  //   return (
  //     isFormFieldValid(name) && (
  //       <small className="p-error">{formik.errors[name]}</small>
  //     )
  //   );
  // };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        setAddress(wa);
        const resp = await axios.post(url + "/user/getdeposits", { wa });
        if (resp.status === 200) {
          setDeposits({
            totDepost: resp.data.totDepost,
            totWithdraw: resp.data.totWithdraw,
          });
        }
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // var bal = await provider.getBalance(wa);
        // bal = ethers.utils.formatEther(bal);
        // var x = await reqSoarx(10);
        // setData({
        //   reqsoarx: x.reqsrx,
        //   gasfee: x.gasFee,
        //   totalsoarx: x.total,
        //   oneusdt: x.oneusdt,
        // });
        // setTotsoarx(x.total);
        // setAddress(wa);
        // setBalance(bal);
        // formik.setFieldValue("reqsoarx", x.total);
        // formik.setFieldValue("soarx", x.reqsrx);
        // if (bal * 1 < x.total * 1) {
        //   show(
        //     "error",
        //     "Error",
        //     "You do not have sufficient balance to register."
        //   );
        // } else {
        //   setButtonDisabled(1);
        // }
      }
    };
    fetchData();
  }, []);
  const show = (x, title, msg) => {
    toast.current.show({ severity: x, summary: title, detail: msg });
  };
  // const reqSoarx = async (usdt) => {
  //   var srxprice = 0.42;
  //   var usdtprice = 92;
  //   var reqSoar = (usdtprice / srxprice) * usdt;
  //   reqSoar = reqSoar.toFixed(0);
  //   var gas = (usdtprice / srxprice) * 0.5;
  //   var oneusdt = (usdtprice / srxprice) * 1;
  //   gas = gas.toFixed(0);
  //   return {
  //     reqsrx: reqSoar,
  //     gasFee: gas,
  //     total: reqSoar * 1 + gas * 1,
  //     oneusdt: oneusdt,
  //   };
  // };

  // const usdtChanged = (usdtval) => {
  //   formik.setFieldValue("usdt", usdtval);
  //   formik.setFieldValue("soarx", (data.oneusdt * usdtval).toFixed(0));
  //   setTotsoarx((data.oneusdt * usdtval).toFixed(0) * 1 + data.gasfee * 1);
  // };
  // const soarxChanged = (soarx) => {
  //   formik.setFieldValue("soarx", soarx);
  //   formik.setFieldValue("usdt", parseInt((1 / data.oneusdt) * soarx));
  //   setTotsoarx(soarx * 1 + data.gasfee * 1);
  // };
  const withdrawusdt = async () => {
    if (
      withamt * 1 > 50 ||
      withamt * 1 > deposits.totDepost * 1 - deposits.totWithdraw * 1
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Sorry!!",
      });
      return;
    } else {
      setButtonDisabled(1)
      const resp = await axios.post(url + "/user/xwithdraw", {
        address,
        withamt,
      });
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    }
  };
  return (
    <div className="soarxdeposit">
      <Toast ref={toast} />
      <div className="grid">
        {/* <div className="col-12 md:col-6">
          <Card title="Soarx Deposit">
            <div className="flex gap-2">
              <div>{deposits.totDepost}</div>
              <div>{deposits.totWithdraw}</div>
              <div>{deposits.totDepost - deposits.totWithdraw}</div>
            </div>
            <Alert severity="error" sx={{ marginBottom: 1 }}>
              Minimum soarX is: {data.reqsoarx}, and {data.gasfee} SOARX for
              network fee.
            </Alert>
            <Alert sx={{ marginBottom: 1 }}>
              Available SOARX: {(balance * 1).toFixed(2)}
            </Alert>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div>
                    <div>INVESTING SOARX</div>
                    <div className="srxusdt">
                      <InputText
                        type="number"
                        name="soarx"
                        className="p-inputtext-sm first"
                        placeholder="SOARX"
                        onChange={(e) => soarxChanged(e.target.value)}
                        value={formik.values.soarx}
                      />
                      <InputText
                        type="number"
                        name="usdt"
                        className="p-inputtext-sm second"
                        placeholder="USDT"
                        onChange={(e) => usdtChanged(e.target.value)}
                        value={formik.values.usdt}
                      />
                    </div>
                    {getFormErrorMessage("usdt")}
                    {getFormErrorMessage("soarx")}
                  </div>
                </div>
                <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
                  Total {totsoarx} will be deducted from your wallet.
                </Alert>
                {isButtonDisabled === 0 ? (
                  <Button label="Loading..." typeof="button" disabled />
                ) : (
                  <>
                    {isButtonDisabled === 1 ? (
                      <Button type="submit" label="Submit" />
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        label="Submitting..."
                        icon="pi pi-spin pi-spinner"
                      />
                    )}
                  </>
                )}
              </Form>
            </FormikProvider>
          </Card>
        </div> */}
        <div className="col-12 md:col-6">
          <Card title="Request Withdraw">
            <div className="flex gap-2 bg-primary p-3 mb-2 border-round">
              <div>IN: {deposits.totDepost}</div>
              <div> OUT: {deposits.totWithdraw}</div>
              <div>BALANCE: {deposits.totDepost - deposits.totWithdraw}</div>
            </div>
            <div className="form-group">
              <InputText
                placeholder="Withdraw"
                autoFocus
                value={withamt}
                onChange={(e) => setWithAmt(e.target.value)}
              />
              {deposits.allowwithdraw === 0 ? (
                <Button label="Withdraw" />
              ) : (
                <>
                {isButtonDisabled===0?
                <Button label="Withdraw" onClick={() => withdrawusdt()} />
                :
                <Button label="Withdrawing...." />
                }
                </>
                
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
