import React, { useEffect, useRef, useState } from "react";
import "./withdraw.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ethers } from "ethers";
import moment from "moment";
import abi from "../../../ABI.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
export const Withdraw = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const toast = useRef(null);
  const [newwithdraw, setNewWithdraw] = useState([]);
  const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isCButtonDisabled, setCButtonDisabled] = useState(false);
  const [address, setAddress] = useState("");
  const [allowWithdraw, setAllowWithdraw] = useState(0);
  const [visible, setVisible] = useState(false);
  // const [balance, setBalance] = useState(0);
  const [data, setData] = useState({
    reqsoarx: "0",
    gasfee: "0",
    totalsoarx: "0",
    oneusdt: 0,
  });
  const validationSchema = Yup.object({
    withdraw: Yup.number("Invalid value found.")
      .required("This field is required.")
      .min(4, "Minimum 3 USDT is required.")
      .max(Yup.ref("avlusdt")),
  });
  const formik = useFormik({
    initialValues: {
      avlusdt: 0,
      withdraw: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        setButtonDisabled(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        var soar = data.gasfee;
        soar = soar.toString();
        var mycontract = new ethers.Contract(contract, abi, signer);
        try {
          mycontract
            .contribute({ value: ethers.utils.parseUnits(soar, "ether") })
            .then(function (done) {
              axios.post(url + "/user/withdraw", { wa }).then(function (resp) {
                setButtonDisabled(false);
                formik.values.avlusdt = parseInt(0);
                formik.values.withdraw = parseInt(0);
                setVisible(true);
              });
            })
            .catch(function (error) {
              setButtonDisabled(false);
            });
        } catch (error) {
          setButtonDisabled(false);
        }
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const resp = await axios.post(url + "/user/dbdata", {
          wa,
        });
        
        //console.log(resp.data.totinvest, resp.data.totalIncome, resp.data.reward)
        var isTopUp =
          resp.data.totinvest * 4 - (resp.data.totalIncome - resp.data.reward);
        //console.log(isTopUp)
        const user = await axios.post(url + "/user/checkuser", { wa });
        const withdraws = await axios.post(url + "/user/withdrawlist", { wa });
        setNewWithdraw(withdraws.data.withdraws);
        let avlusdt = parseInt(resp.data.balance);
        if (avlusdt * 1 <= 0) {
          avlusdt = 0;
        }
        var remainbal =
          resp.data.totinvest * 4 - (resp.data.totalIncome - resp.data.reward);
        if (
          avlusdt * 1 <= 4 ||
          user.data.allow_withdraw == 0 ||
          isTopUp * 1 < 0
        ) {
          setAllowWithdraw(0);
        } else {
          setAllowWithdraw(1);
        }
        formik.values.avlusdt = parseInt(avlusdt);
        formik.values.withdraw = parseInt(avlusdt);
        // console.log("Check")
        var x = await reqSoarx(1);
        // console.log(x)
        setData({
          reqsoarx: x.reqsrx,
          gasfee: x.gasFee,
          totalsoarx: x.total,
          oneusdt: x.oneusdt,
        });
      }
    };
    fetchData();
  }, []);
  const reqSoarx = async (usdt) => {
    const resp = await axios.get("https://api.coindcx.com/exchange/ticker");
    var mydata = resp.data;
    var usdtinr = mydata.filter(function (entry) {
      return entry.market === "USDTINR";
    });
    // const soarxprice = await axios.get(
    //   "https://api.soarxbits.com/ticker/tickers/SOARXINR"
    // );
    // var srxprice = soarxprice.data.ticker.high;
    var srxprice = 0.52
    var usdtprice = 90;
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    var oneusdt = (usdtprice / srxprice) * 1;
    gas = gas.toFixed(0);
    return {
      reqsrx: reqSoar,
      gasFee: gas,
      total: reqSoar * 1 + gas * 1,
      oneusdt: oneusdt,
    };
  };
  const checkTransfer = async (tranid, wa) => {
    if(window.ethereum){
      setCButtonDisabled(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      var mycontract = new ethers.Contract(contract, abi, signer);
      mycontract.fetchWithdraw(wa, tranid).then(function (done) {
        alert("Here...")
        if (parseInt(done) === 0) {
          axios
            .post(url + "/user/withdrawcheck", {
              tranid,
              wa,
            })
            .then(function (resp) {
              window.location.reload(true);
              console.log(resp.data);
            });
        }
      });
    }
    
  };
  return (
    <div className="withdraw">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6">
          <div className="p-1 border-round-sm font-bold">
            <Card title="Withdraw">
              <Alert severity="error" sx={{ marginBottom: 1 }}>
                Minimum {data.gasfee} SOARX will be charged for network fee.
              </Alert>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>
                      <div className="avail">
                        <div>Available $</div>
                      </div>
                      <div>
                        <InputText
                          type="number"
                          name="avlusdt"
                          className="p-inputtext-sm second"
                          placeholder="USDT"
                          readOnly
                          onChange={formik.handleChange}
                          value={formik.values.avlusdt}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="avail">WITHDRAW $</div>
                      <div>
                        <InputText
                          type="number"
                          name="withdraw"
                          className="p-inputtext-sm second"
                          placeholder="USDT"
                          onChange={formik.handleChange}
                          value={formik.values.withdraw}
                        />
                      </div>
                      {getFormErrorMessage("withdraw")}
                    </div>
                  </div>
                  <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
                    Withdrawal will be credited to default wallet as soarx
                    coin(s).
                  </Alert>
                  {allowWithdraw == 1 ? (
                    <>
                      {isButtonDisabled === false ? (
                        <Button type="submit" label="Submit" />
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          label="Submitting..."
                          icon="pi pi-spin pi-spinner"
                        />
                      )}
                    </>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      label="Sorry"
                      icon="pi pi-ban"
                    />
                  )}
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <div className="p-1 border-round-sm font-bold">
            <Card title="Withdraw List">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="left">USDT</TableCell>
                      <TableCell align="left">Check</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newwithdraw.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {moment(row.tran_date).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="left">{row.usd_val}</TableCell>
                        <TableCell align="left">
                          {row.is_conf == 0 ? (
                            <>
                              {isCButtonDisabled === false ? (
                                <Button
                                  label="Check"
                                  onClick={() =>
                                    checkTransfer(row.tran_id, row.mem_address)
                                  }
                                />
                              ) : (
                                <Button
                                  label="Check......"
                      
                                />
                              )}
                            </>
                          ) : (
                            "NA"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>
      </div>

      <Dialog
        header="Congratulation"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">Withdraw done Sucessfull!</p>
      </Dialog>
    </div>
  );
};
