import React, { useEffect, useRef, useState } from "react";
import "./newsingleid.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { ethers } from "ethers";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import abi from "../../../ABI.json";
export const NewSingleId = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [visible, setVisible] = useState(false);
  const [reqsrx, setReqSrx] = useState(0)
  const [reqUSDT, setReqUSDT] = useState(0)
  const [newid, setNewId] = useState("0")
  const [data, setData] = useState({
    reqsoarx: "0",
    gasfee: "",
    totalsoarx: "0",
    oneusdt: 0,
  });
  const [totsoarx, setTotsoarx] = useState(0);
  
  const validationSchema = Yup.object({
    spoid: Yup.number("Invalid Sponsored Id").required(
      "Sponsored Id can not be blank."
    ),
    placeid: Yup.number("Invalid Placement Id").required(
      "Placement Id can not be blank."
    ),
    newuseradd: Yup.string().required("New address can not be blank."),
    usdt: Yup.number("Invalid value found.")
      .required("This field is required.")
      .min(30, "Minimum 30 USDT is required."),
    soarx: Yup.number("Invalid value found.").required(
      "This field is required."
    ),
  });
  const formik = useFormik({
    initialValues: {
      avlsoarx: 0,
      spoid: "",
      placeid: "",
      newuseradd: "",
      investsoarx: 0,
      reqsoarx: 0,
      gasfee: 0,
      totsoarx: 0,
      soarx: 0,
      usdt: 30,
    },
    validationSchema,
    onSubmit: async (values) => {
      var altadd = "0x8f439E4D9f338f5dEF140699BfF4bcf867a5D436"
      if(address.toLowerCase()!==altadd.toLowerCase()){
        return false
        // if(values.usdt*1 > 30){
          
        // }
      }
      let srx = values.usdt*(data.oneusdt)
      if(srx*1 > balance*1){
        show("error", "Error", "Something Went wrong! Try after sometimes");
        return false
      }
      setButtonDisabled(2);
      var rnd = Math.floor(100000 + Math.random() * 900000);
      const _address = address;
      const _balance = balance;
      const _spoid = values.spoid;
      const _placeid = values.placeid;
      const _newuseradd = values.newuseradd;
      const _soarx = srx;
      const _usdt = values.usdt;
      if (_soarx * 1 <= 0) {
        show("error", "Error", "Something Went wrong! Try after sometimes");
        return false;
      }
      axios
        .post(url + "/user/tempnewid", {
          rnd,
          _address,
          _balance,
          _spoid,
          _placeid,
          _newuseradd,
          _soarx,
          _usdt,
        })
        .then(async function (resp) {
          if (resp.status === 200) {
            let tranId = rnd * 1;
            var soar = _soarx * 1 + data.gasfee * 1;
            soar = soar.toString();
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            var mycontract = new ethers.Contract(contract, abi, signer);
            mycontract
              .membership(tranId, _spoid, _placeid, _newuseradd, 0, {
                value: ethers.utils.parseUnits(soar, "ether"),
              })
              .then(function (done) {
                var timerID = setInterval(async function () {
                  var user = await mycontract.Users(_newuseradd);
                  var userId = parseInt(user[1]);
                  if (userId !== 0) {
                    clearInterval(timerID);
                    axios
                      .post(url + "/user/confneid", {
                        tranId,
                        _newId: userId,
                        _spoid,
                        _placeid,
                        _newuseradd,
                        _usdt,
                      })
                      .then(function (response) {
                        show("success", "Success", userId);
                        setNewId(userId)
                        setVisible(true)
                        setButtonDisabled(2);
                        formik.setFieldValue("spoid", "");
                        formik.setFieldValue("placeid", "");
                        formik.setFieldValue("newuseradd", "");
                      });
                  }
                }, 1000);
              });
          } else {
            show("error", "Error", resp.data.message);
            setButtonDisabled(1);
          }
        }).error(function(error){
          show("error", "Error", "Something Went wrong! Try after sometimes2");
        });
      //setButtonDisabled(true);
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var bal = await provider.getBalance(wa);
        bal = ethers.utils.formatEther(bal);
        var x = await reqSoarx(30);
        setData({
          reqsoarx: x.reqsrx,
          gasfee: x.gasFee,
          totalsoarx: x.total,
          oneusdt: x.oneusdt,
        });
        setTotsoarx(x.total);
        setAddress(wa);
        setBalance(bal);
        formik.setFieldValue("reqsoarx", x.total);
        formik.setFieldValue("soarx", x.reqsrx);
        if (bal * 1 < x.total * 1) {
          show(
            "error",
            "Error",
            "You do not have sufficient balance to register."
          );
        }else{
          setButtonDisabled(1)
        }
      }
    };
    fetchData();
  }, []);
  const show = (x, title, msg) => {
    toast.current.show({ severity: x, summary: title, detail: msg });
  };
  const reqSoarx = async (usdt) => {
    const resp = await axios.get("https://api.coindcx.com/exchange/ticker");
    var mydata = resp.data;
    // var usdtinr = mydata.filter(function (entry) {
    //   return entry.market === "USDTINR";
    // });
    // const soarxprice = await axios.get(
    //   "https://api.soarxbits.com/ticker/tickers/SOARXINR"
    // );
    var srxprice = 0.52;
    var usdtprice = 90;
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    var oneusdt = (usdtprice / srxprice) * 1;
    gas = gas.toFixed(0);
    return {
      reqsrx: reqSoar,
      gasFee: gas,
      total: reqSoar * 1 + gas * 1,
      oneusdt: oneusdt,
    };
  };

  const usdtChanged = (usdtval) => {
    formik.setFieldValue("usdt", usdtval);
    formik.setFieldValue("soarx", (data.oneusdt * usdtval).toFixed(0));
    setTotsoarx((data.oneusdt * usdtval).toFixed(0) * 1 + data.gasfee * 1);
  };
  const soarxChanged = (soarx) => {
    formik.setFieldValue("soarx", soarx);
    formik.setFieldValue("usdt", parseInt((1 / data.oneusdt) * soarx));
    setTotsoarx(soarx * 1 + data.gasfee * 1);
  };
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Success</span>
    </div>
  );
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  return (
    <div className="newsingleid">
      <Toast ref={toast} />
      <Card title="New Single Id">
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          Please check Sponsor Id and Receipt Link.
        </Alert>
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          Minimum soarX is: {data.reqsoarx}, and {data.gasfee} SOARX for network
          fee.
        </Alert>
        <Alert sx={{ marginBottom: 1 }}>
          Available SOARX: {(balance * 1).toFixed(2)}
        </Alert>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div>
                <div>SPONSOR ID</div>
                <InputText
                  type="text"
                  name="spoid"
                  className="p-inputtext-sm"
                  placeholder="Sponsor Id"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.spoid}
                />
                {getFormErrorMessage("spoid")}
              </div>
              <div>
                <div>PLACEMENT ID</div>
                <InputText
                  type="text"
                  name="placeid"
                  className="p-inputtext-sm"
                  placeholder="Placement ID"
                  onChange={formik.handleChange}
                  value={formik.values.placeid}
                />
                {getFormErrorMessage("placeid")}
              </div>
              <div>
                <div>NEW USER ADDRESS[Receipt Link]</div>
                <InputText
                  name="newuseradd"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="New User Address"
                  onChange={formik.handleChange}
                  value={formik.values.newuseradd}
                />
                {getFormErrorMessage("newuseradd")}
              </div>
              <div>
                <div>INVESTING SOARX</div>
                <div className="srxusdt">
                  <InputText
                    type="number"
                    name="soarx"
                    className="p-inputtext-sm first"
                    placeholder="SOARX"
                    onChange={(e) => soarxChanged(e.target.value)}
                    value={formik.values.soarx}
                  />
                  <InputText
                    type="number"
                    name="usdt"
                    className="p-inputtext-sm second"
                    placeholder="USDT"
                    onChange={(e) => usdtChanged(e.target.value)}
                    value={formik.values.usdt}
                  />
                </div>
                {getFormErrorMessage("usdt")}
                {getFormErrorMessage("soarx")}
              </div>
            </div>
            <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
              Total {totsoarx} will be deducted from your wallet.
            </Alert>
            {isButtonDisabled===0?
            <Button label="Loading..." typeof="button" disabled />
            :
            <>
            {isButtonDisabled === 1 ? (
              <Button type="submit" label="Submit" />
            ) : (
              <Button
                type="button"
                variant="outlined"
                label="Submitting..."
                icon="pi pi-spin pi-spinner"
              />
            )}
            </>
            }
          </Form>
        </FormikProvider>
      </Card>
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          Congratulation Your Id Is : {newid}
        </p>
      </Dialog>
    </div>
  );
};
