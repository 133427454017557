import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
export const DbWelcome = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState({});
  const [userdata, setUserData] = useState({
    rank: "NO RANK",
    reward: "NO REWARD",
    userid: "",
    memaddress: "",
    mydown: 0,
    team: 0,
    totinvest: 0,
    totincome: 0,
    reward: 0,
    reqsoarx: 0,
    oneusdt: 0,
    msg: "",
    stack: 0,
    primember:0
  });
  const [userrank, setUserRank] = useState("NO RANK");
  const [userreward, setUserReward] = useState("NO REWARD");
  const [business, setBusiness] = useState({
    totinvest: 0,
    gTotIncome: 0,
    totLevel: 0,
  });
  var _rank = [
    "NO RANK",
    "SILVER",
    "PLATINUM",
    "SUPER PLATINUM",
    "GOLD",
    "SUPER GOLD",
  ];
  var _reward = [
    "NO REWARD",
    "REWARD-1",
    "REWARD-2",
    "REWARD-3",
    "REWARD-4",
    "REWARD-5",
    "REWARD-6",
    "REWARD-7",
    "REWARD-8",
    "REWARD-9",
    "REWARD-10",
    "REWARD-11",
    "REWARD-12",
    "REWARD-13",
    "REWARD-14",
  ];
  //const [reward, setReward] = useState("0.00");
  //const [soarx, setSoarx] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const dbdata = await axios.post(url + "/user/dbdata", {
          wa,
        });
        setUserData({
          rank: dbdata.data.userdata.rank,
          reward: dbdata.data.userdata.reward,
          userid: dbdata.data.userdata.memberid,
          memaddress: dbdata.data.userdata.mem_address,
          mydown: dbdata.data.userdata.mydown,
          team: dbdata.data.userdata.myfund * 1 + dbdata.data.userdata.teamfund,
          totinvest: dbdata.data.totinvest,
          totincome: dbdata.data.totalIncome,
          reward: dbdata.data.reward,
          reqsoarx: dbdata.data.reqSoar,
          oneusdt: dbdata.data.oneusdt,
          msg: dbdata.data.msg,
          stack: dbdata.data.stlegth,
          primember:dbdata.data.primemember
        });
        setUserRank(_rank[dbdata.data.userdata.rank]);
        setUserReward(_reward[dbdata.data.userdata.reward]);
      } else {
        console.log("ERROR");
      }
    };
    
    fetchData();
  }, []);
  const checkRank = async () => {
    if (window.ethereum) {
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0];
      const resp = await axios.post(url + "/user/checkrank", { wa });
      if (resp.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Rank Updated Successfully.",
        });
        setUserRank(_rank[resp.data.data]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "You are not qualified for New Rank.",
        });
      }
    } else {
      alert("Not Connected");
    }
  };
  const checkReward = async () => {
    if (window.ethereum) {
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0];
      const resp = await axios.post(url + "/user/checkreward", { wa });
      if (resp.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Reward Updated Successfully.",
        });
        setUserReward(_reward[resp.data.data]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "You are not qualified for New Reward.",
        });
      }
    } else {
      alert("Not Connected");
    }
  };
  
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
  };
  return (
    <div className="dbwelcome">
      <Toast ref={toast} />
      {userdata.msg == "" ? (
        ""
      ) : (
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-12">
            <div className="p-1 border-round-sm font-bold">
              <Card className="content top">{userdata.msg}</Card>
            </div>
          </div>
        </div>
      )}

      <div className="grid">
        <div className="col-12 md:col-6 lg:col-7">
          <div className="p-1 border-round-sm font-bold">
            <Card className="content">
              <div className="firstgrp">
                <span>Welcome Back Member:</span>
                <span className="memberid">{userdata.userid}</span> 🎉{" "}
              </div>
              <div>
                Your Rank is: <span className="rank">{userrank}</span> and your
                Active reward is: <span className="rank">{userreward} </span>.
              </div>
              <div className="but">
                <Button label="Check Rank" onClick={() => checkRank()} />
                <Button label="Check Reward" onClick={() => checkReward()} />
              </div>
              <div className="address">
                <div className="copy">
                  <span>Address</span>
                  <span className="pi pi-copy ml-2"></span>
                </div>
                <span style={{ fontSize: 12 }} className="rank">
                  {userdata.memaddress}
                </span>
              </div>
              <div className="reflink">
                <div className="copy">
                  <span>Ref Link</span>
                  <span className="pi pi-copy ml-2"></span>
                </div>
                <span style={{ fontSize: 12 }} className="rank">
                  https://misoarx.io/register?sponsorid={userdata.userid}
                </span>
              </div>
              <div>
                Require Soar: <span className="rank">{userdata.reqsoarx}</span>{" "}
                || Current Price 1 USDT :{" "}
                <span className="rank">{userdata.oneusdt}</span>
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-5">
          <div className="grid">
            <div className="col-6 md:col-6 lg:col-6">
              <div className="text-center p-3 cust-bg">
                <img src="/icons/downline.webp" width={40} alt="" />
                <div>
                  <Link to="/user/downline">My Downline</Link>
                </div>
                <div>{userdata.mydown}</div>
              </div>
            </div>
            <div className="col-6 md:col-6 lg:col-6">
              <div className="text-center p-3 cust-bg">
                <img src="/icons/team.webp" width={40} alt="" />
                <div>
                  <Link>Business</Link>
                </div>
                <div>{userdata.team.toFixed(0)}</div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-12 lg:col-12">
              <div className="text-center p-3 cust-bg">
                <img src="/icons/total.webp" width={155} alt="" />
                <div>
                  <Link>Total Income</Link>
                </div>
                <div>{userdata.totincome.toFixed(3)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/invest.webp" width={40} alt="" />
            <div>
              <Link to={userdata.stack*1>=0?'/user/stacklist':'#'}>Stacking</Link>
            </div>
            <div>{userdata.stack.toFixed(0)}</div>
          </div>
        </div>
        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/invest.webp" width={40} alt="" />
            <div>
              <Link>Prime Member</Link>
            </div>
            <div>{userdata.primember==0?'NO':'YES'}</div>
          </div>
        </div>

        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/invest.webp" width={40} alt="" />
            <div>
              <Link>Total Invest</Link>
            </div>
            <div>{userdata.totinvest.toFixed(3)}</div>
          </div>
        </div>
        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/income.webp" width={40} alt="" />
            <div>
              <Link>Total Income</Link>
            </div>
            <div>{(userdata.totincome - userdata.reward).toFixed(3)}</div>
          </div>
        </div>
        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/valid.webp" width={40} alt="" />
            <div>
              <Link>Valid Upto</Link>
            </div>
            <div>{(userdata.totinvest * 4).toFixed(3)}</div>
          </div>
        </div>
        <div className="col-6 md:col-6 lg:col-2">
          <div className="text-center p-3 cust-bg">
            <img src="/icons/remain.webp" width={40} alt="" />
            <div>
              <Link>Remain</Link>
            </div>
            <div>
              {(
                userdata.totinvest * 4 -
                (userdata.totincome - userdata.reward)
              ).toFixed(3)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
