import React from "react";
import { Dock } from "primereact/dock";
import { UserSpeedDial } from "../UserSpeedDial";
import { Card } from "primereact/card";
import "./userfooter.scss"
import {Link, useNavigate} from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
export const UserFooter = () => {
  const navigate = useNavigate();
  // const items = [
  //   {
  //     label: "Home",
  //     icon: () => <img alt="Finder" src="/icons/home.webp" width="100%" />,
  //     command: ()=>{navigate('/user/')}
  //   },
  //   {
  //     label: "New Single Id",
  //     icon: () => (
  //       <img
  //         alt="Photos"
  //         src="/icons/singleid.webp"
  //         width="100%"
  //       />
  //     ),
  //     command: ()=>{navigate('/user/newsingleid/')}
  //   },
  //   {
  //     label: "Topup",
  //     icon: () => (
  //       <img
  //         alt="trash"
  //         src="/icons/othertopup.webp"
  //         width="100%"
  //       />
  //     ),
  //     command: ()=>{navigate('/user/topup')}
  //   },

  //   {
  //     label: "Income Details",
  //     icon: () => (
  //       <img
  //         alt="App Store"
  //         src="/icons/incomdetail.webp"
  //         width="100%"
  //       />
  //     ),
  //     command: ()=>{navigate('/user/incomedetail/')}
  //   },
  //   {
  //     label: "Withdraw",
  //     icon: () => (
  //       <img
  //         alt="trash"
  //         src="/icons/withdraw.webp"
  //         width="100%"
  //       />
  //     ),
  //     command: ()=>{navigate('/user/withdraw/')}
  //   },
  // ];
  return (
    <>
    <div className="userfooter">
      <Card>
        <div className="cont">
          <Link to="/user/">
            <img src="/icons/home.webp" alt="" />
            <div>HOME</div>
          </Link>
        </div>
        {/* <div className="cont">
          <Link to="/user/newsingleid">
            <img src="/icons/singleid.webp" alt="" />
            <div>SINGLE ID</div>
          </Link>
        </div> */}
        {/* <div className="cont">
          <Link to="/user/topup">
            <img src="/icons/othertopup.webp" alt="" />
            <div>TOPUP</div>
          </Link>
        </div> */}
        {/* <div className="cont">
          <Link to="#">
            <img src="/icons/home.webp" alt="" />
            <div>STACK</div>
          </Link>
        </div> */}
        <div className="cont">
          <Link to="/user/incomedetail">
            <img src="/icons/incomdetail.webp" alt="" />
            <div>BONUS</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/usdtwithdraw">
            <img src="/icons/othertopup.webp" alt="" />
            <div>USDT WITHDRAW</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/withdraw">
            <img src="/icons/withdraw.webp" alt="" />
            <div>WITHDRAW</div>
          </Link>
        </div>
      </Card>
    </div>
      {/* <div className="card my-dock">
        <div
          className="dock-window"
          style={{
            position: "fixed",
            left: "50%",
            bottom: "10px",
          }}
        > */}
          
          {/* <Tooltip className="dark-tooltip" target=".p-dock-action" my="center+15 bottom-15" at="center top" showDelay={150} /> */}
          {/* <Dock model={items} position="bottom" /> */}
        {/* </div> */}
      {/* </div> */}
      {/* <UserSpeedDial /> */}
    </>
  );
};
