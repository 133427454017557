import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment";
import { Toast } from "primereact/toast";
import { Button } from "@mui/material";
export const WithdrawUsdtAdm = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/usdtwithlist");
      if (resp.status === 200) {
        setData(resp.data.data);
      }
    };
    fetchData();
  }, []);
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "Address Copied Successfully",
    });
  };
  const paidConf = async(_id)=>{
    const resp = await axios.post(url + "/admin/usdtpaid",{_id})
    if(resp.status===200){
        window.location.reload(true)
    }
  }
  const custdate = (data) => {
    return <>{moment(data.tran_date).format("YYYY-MM-DD")}</>;
  };
  const custadd = (data) => {
    return (
      <div className="flex">
        <div>{data.mem_address}</div>
        <div>
          <i
            className="pi pi-copy cursor-pointer"
            onClick={() => copyText(data.mem_address)}
          ></i>
        </div>
      </div>
    );
  };
  const tranbtn = (data) => {
    return (
      <>
        <Button onClick={()=>paidConf(data._id)}>Paid</Button>
      </>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <DataTable value={data}>
        <Column field={custdate} header="Date"></Column>
        <Column field={custadd} header="Address"></Column>
        <Column field="usd_val" header="Quantity"></Column>
        <Column body={tranbtn} header="Status"></Column>
      </DataTable>
    </div>
  );
};
