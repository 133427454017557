// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/icons/singleid.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/icons/othertopup.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/icons/selftopup.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../public/icons/withdraw.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .userspeeddial .p-speeddial-action {
  background-color: transparent;
}
.theme-light .userspeeddial .bgsingleid {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}
.theme-light .userspeeddial .bgothertopup {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: cover;
}
.theme-light .userspeeddial .bgselftopup {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-size: cover;
}
.theme-light .userspeeddial .bgwithdraw {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-size: cover;
}
.theme-dark .userspeeddial .p-speeddial-action {
  background-color: transparent;
}
.theme-dark .userspeeddial .bgsingleid {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}
.theme-dark .userspeeddial .bgothertopup {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: cover;
}
.theme-dark .userspeeddial .bgselftopup {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-size: cover;
}
.theme-dark .userspeeddial .bgwithdraw {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/usercomponent/userspeeddial.scss"],"names":[],"mappings":"AAGI;EACI,6BAAA;AAFR;AAII;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AAFR;AAII;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AAFR;AAII;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AAFR;AAII;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AAFR;AA3BI;EACI,6BAAA;AA6BR;AA3BI;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AA6BR;AA3BI;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AA6BR;AA3BI;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AA6BR;AA3BI;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,sBAAA;AA6BR","sourcesContent":["@import \"../../style.scss\";\n.userspeeddial {\n  @include themify($themes) {\n    .p-speeddial-action{\n        background-color: transparent;\n    }\n    .bgsingleid{\n        display: flex;\n        width: 100%;\n        height: 100%;\n        background-image: url('../../../public/icons/singleid.webp');\n        background-size: cover;\n    }\n    .bgothertopup{\n        display: flex;\n        width: 100%;\n        height: 100%;\n        background-image: url('../../../public/icons/othertopup.webp');\n        background-size: cover;\n    }\n    .bgselftopup{\n        display: flex;\n        width: 100%;\n        height: 100%;\n        background-image: url('../../../public/icons/selftopup.webp');\n        background-size: cover;\n    }\n    .bgwithdraw{\n        display: flex;\n        width: 100%;\n        height: 100%;\n        background-image: url('../../../public/icons/withdraw.webp');\n        background-size: cover;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
