import React, { useEffect, useRef, useState } from "react";
import "./spgold.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
export const SpGoldCalc = () => {
    const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [data, setData] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const calculate = async () => {
    const resp = await axios.post(url + "/admin/spgoldcalc", {
      startdate,
      enddate,
    });
    console.log(resp);
  };
  return (
    <div className="spgoldcalc">
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-4 left">
          <Card>
            <div className="form-group">
              <div className="cont">
                <div>Start Date</div>
                <InputText
                  type="date"
                  className="p-inputtext-sm"
                  placeholder="Member Id"
                  value={startdate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </div>
              <div className="cont">
                <div>End Date</div>
                <InputText
                  type="date"
                  className="p-inputtext-sm"
                  placeholder="Member Id"
                  value={enddate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
              <Button label="Calculate" onClick={() => calculate()} />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
