import React, { useEffect, useRef, useState } from "react";
import "./usdtwithdraw.scss";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
export const UsdtWithdraw = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [allowWithdraw, setAllowWithdraw] = useState(0);
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState({});
  const [max, setMax] = useState(0);
  const [isclient, setIsClient] = useState(0);
  const [masteradd, setMasterAdd] = useState("");
  const [btntrans, setBtnTrans] = useState(0)
  const [userData, setUserData] = useState({
    totinvest: 0,
    rewardAmt: 0,
    totincome: 0,
    withdraw: 0,
    canwithdraw: 0,
  });
  const validationSchema = Yup.object({
    withdraw: Yup.number("Invalid value found.")
      .required("This field is required.")
      .min(4, "Minimum 3 USDT is required.")
      .max(Yup.ref("avlusdt")),
  });
  const formik = useFormik({
    initialValues: {
      avlusdt: 0,
      withdraw: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.withdraw * 1 > max * 1) {
        return false;
      }
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        setButtonDisabled(true);
        const resp = await axios.post(url + "/user/usdtwith", { values, wa });
        if (resp.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: resp.data.success,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: resp.data.error,
          });
          setButtonDisabled(false);
        }
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const resp = await axios.post(url + "/user/usddbdata", {
          wa,
        });
        if (resp.status === 200) {
          if (resp.data.userdata.lastmonthsilver === 1) {
            if(resp.data.allowwithdraw===1){
              setAllowWithdraw(1);
            }
          }
          formik.setFieldValue("avlusdt", parseInt(resp.data.canWithdraw))
          formik.setFieldValue("withdraw", parseInt(resp.data.canWithdraw))
          setData(resp.data.usd);
          setMax(resp.data.setdata.core);
          setIsClient(resp.data.isclient);
          setMasterAdd(resp.data.masteradd);
          setUserData({
            totinvest: resp.data.totinvest,
            rewardAmt: resp.data.rewardAmt,
            totincome: resp.data.totalIncome,
            withdraw: resp.data.withdraw,
            canwithdraw: resp.data.canWithdraw,
          });
        }
      }
    };
    fetchData();
  }, []);
  const transtatus = (data) => {
    return <>{data.is_conf === 0 ? "Proccesing..." : "Conf"}</>;
  };
  const custdate = (data) => {
    return <>{moment(data.tran_date).format("YYYY-MM-DD")}</>;
  };
  const Transfer = async () => {
    if (window.ethereum) {
      setBtnTrans(1)
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0];
      const resp = await axios.post(url + "/user/TransferToMaster", {
        wa,
        masteradd,
      });
      if(resp.status===200){
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      }else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    }
    //alert(masteradd)
  };
  return (
    <div className="usdtwithdraw">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6">
          <div className="p-1 border-round-sm font-bold">
            <Card title="USDT Withdraw">
              <Alert severity="error" sx={{ marginBottom: 1 }}>
                Auto USDT withdraw takes 21 block confirmation.
              </Alert>
              <div className="flex gap-2 p-2 bg-primary">
                <div>INVEST: {(userData.totinvest * 1).toFixed(2)}</div>
                <div>VALID: {(userData.totinvest * 4).toFixed(2)}</div>
                <div>REMAIN: {(balance * 1).toFixed(2)}</div>
              </div>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>
                      <div className="avail">
                        <div>Available $ {parseInt(userData.canwithdraw)}</div>
                      </div>
                      <div>
                        <InputText
                          type="number"
                          name="avlusdt"
                          className="p-inputtext-sm second"
                          placeholder="USDT"
                          readOnly
                          onChange={formik.handleChange}
                          value={formik.values.avlusdt}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="avail">WITHDRAW [MAX: {max}]</div>
                      <div>
                        <InputText
                          type="number"
                          name="withdraw"
                          className="p-inputtext-sm second"
                          placeholder="USDT"
                          onChange={formik.handleChange}
                          value={formik.values.withdraw}
                        />
                      </div>
                      {getFormErrorMessage("withdraw")}
                    </div>
                  </div>
                  <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
                    Withdrawal fee will be deducted on block confirmation.
                  </Alert>
                  {isclient === 1 ? (
                    <>
                    {btntrans===0?
                    <Button
                    type="button"
                    label="Transfer"
                    severity="info"
                    onClick={() => Transfer()}
                  />
                    :
                    <Button
                      type="button"
                      label="Transfering..."
                      severity="info"
                    />
                    }
                    </>
                    
                  ) : (
                    <>
                    {allowWithdraw === 1 ? (
                    <>
                      {isButtonDisabled === false ? (
                        <Button type="submit" label="Submit" />
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          label="Submitting..."
                          icon="pi pi-spin pi-spinner"
                        />
                      )}
                    </>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      label="Sorry"
                      icon="pi pi-ban"
                    />
                  )}
                    </>
                  )}
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <DataTable value={data}>
            <Column field={custdate} header="Date"></Column>
            <Column field="usd_val" header="Quantity"></Column>
            <Column body={transtatus} header="Status"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
