import React, { useEffect, useRef, useState } from "react";
import "./downline.scss";
import { Card } from "primereact/card";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "primereact/button";
export const Downline = () => {
  const [products, setProducts] = useState([]);
  const url = process.env.REACT_APP_HOST_ADDR;
  const token = '123'
  useEffect(() => {
    if (window.ethereum) {
      const fetchData = async () => {
        const adrs = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const wa = adrs[0];
        const resp = await axios.post(url + "/user/getdownline",{wa},
        {
            headers:{
                'Authorization': "Bearer " + token,
            } 
        }
        );
        //console.log(resp.data.downline);
        setProducts(resp.data.downline);
      };
      fetchData();
    }
  }, []);
  const getDownLine = async (wa) =>{
    const resp = await axios.post(url + "/user/getdownline",{wa},
        {
            headers:{
                'Authorization': "Bearer " + token,
            } 
        }
    );
    //console.log(resp.data.downline);
    setProducts(resp.data.downline);
  }
  return <div className="downline">
    <div className="card" style={{ marginTop: 5 }}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Member Id</TableCell>
            <TableCell align="left">Down</TableCell>
            <TableCell align="left">Own Fund</TableCell>
            <TableCell align="left">Team Fund</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row) => (
            <TableRow
              key={row.memberid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.memberid}
              </TableCell>
              <TableCell align="left">{row.mydown*1 > 0 ?
              <Button label={row.mydown} size="small" onClick={()=>getDownLine(row.mem_address)} />
              :
              <Button label="0" severity="danger" size="small" />
              }</TableCell>
              <TableCell align="left">{(row.myfund*1).toFixed(2)}</TableCell>
              <TableCell align="left">{(row.teamfund*1).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
  </div>;
};
