import React from "react";
import "./officedashboard.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
export const OfficeDashboard = () => {
  return (
    <div className="officedashboard">
      <div className="grid">
        <div className="col-6 flex">
          <Link to="/office/tdsreport">
            <Button label="TDS REPORT" className="mr-3" />
          </Link>
          <Link to="/office/gstreport">
          <Button label="GST REPORT" />
          </Link>
        </div>
      </div>
    </div>
  );
};
