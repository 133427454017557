import React, { useEffect, useRef, useState } from "react";
import "./admindashboard.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import abi from "../../../ABI.json";
export const AdminDashboard = () => {
  const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const [toggle, setToggle] = useState(true);
  const [memid, stateChange] = useState("");
  const [memberstatus, setStatus] = useState(true);
  const toast = useRef(null);
  const [coin, setCoin] = useState(0);
  const [data, setData] = useState({
    custDisbursh: 0,
    disDate: "",
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [ismemchange, setMemchange] = useState(false);
  const [isButtonDisabled1, setButtonDisabled1] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    disdate: Yup.date("Invalid Date"),
  });
  const formik = useFormik({
    initialValues: {
      disdate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { disdate } = values;
      setButtonDisabled(true);
      const resp = await axios.post(url + "/admin/setdisbursh", {
        toggle,
        disdate,
      });
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Disbursh Mode Set Successfully.",
        });
        setButtonDisabled(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Disbursh Mode Set Successfully.",
        });
        setButtonDisabled(false);
      }
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getdisbursh");
      var a = moment(resp.data.resp.dis_date);
      var _date = a.utcOffset(300).format("YYYY-MM-DD");
      setData({ disDate: _date });
      formik.setFieldValue("disdate", _date);
      if (resp.data.resp.cust_date == 1) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    };
    fetchData();
  }, []);
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const submitCoin = async () => {
    const resp = await axios.post(url + "/admin/chektopup", { wa: "1" });
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // await provider.send("eth_requestAccounts", []);
    // const signer = provider.getSigner();
    // var mycontract = new ethers.Contract(contract, abi, signer);
    // var soar = coin
    // soar = soar.toString()
    // mycontract.contribute({value:ethers.utils.parseUnits(soar,"ether")}).then(function(resp){
    //   alert("done")
    // })
  };
  const memblockunblock = async () => {
    setMemchange(true);
    const memstatus = memberstatus == true ? "1" : "0";
    const resp = await axios.post(url + "/admin/memstatusupd", {
      memid,
      memstatus,
    });
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Withdrawal Updated Successfully.",
      });
      setMemchange(false);
    }
  };
  const fetchSPGold = async () => {
    setButtonDisabled(true);
    const resp = await axios.get(url + "/admin/fetchspgold");
    if (resp.status == 200) {
      setButtonDisabled(false);
      if (resp.data.data == 2) {
        alert("ALL DATA UPDATED");
      }
    }
  };
  const modifier = async () => {
    // const resp = await axios.get(url + "/admin/modifier");
    // console.log(resp);
  };

  return (
    <div className="admindashboard">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-2">
          <Link to="/admin/addresschange">
            <Button label="Change Address" />
          </Link>
        </div>
        <div className="col-12 md:col-6 lg:col-2">
          <Link to="/admin/businesscheck">
            <Button label="Business Check" />
          </Link>
        </div>
        <div className="col-12 md:col-6 lg:col-2">
          <Link to="/admin/sponsorchange">
            <Button label="Sponsor Change" />
          </Link>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <Link to="/admin/spgoldcalc">
            <Button label="SP GOLD CALCULATION" />
          </Link>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <Link to="/admin/checkinvest">
            <Button label="CHECK INVEST" />
          </Link>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>
                      <InputSwitch
                        checked={toggle}
                        onClick={() => setToggle(!toggle)}
                      />
                    </div>
                    <div>
                      <div>DISBURSEH DATE</div>
                      <InputText
                        type="date"
                        name="disdate"
                        className="p-inputtext-sm"
                        placeholder="Disbursh Date"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.disdate}
                      />
                      {getFormErrorMessage("username")}
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" label="Submit" />
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        icon="pi pi-spin pi-spinner"
                        label="Submitting..."
                      />
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <div className="form-group">
                <div>
                  <InputSwitch
                    checked={memberstatus}
                    onClick={() => setStatus(!memberstatus)}
                  />
                </div>
                <div>
                  <div>Member Id Withdraw On Off</div>
                  <InputText
                    type="number"
                    name="memberid"
                    className="p-inputtext-sm"
                    placeholder="Member Id"
                    value={memid}
                    onChange={(event) => stateChange(event.target.value)}
                  />
                  {getFormErrorMessage("memberid")}
                </div>
                {ismemchange === false ? (
                  <Button
                    type="button"
                    onClick={() => memblockunblock()}
                    label="Submit"
                  />
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    icon="pi pi-spin pi-spinner"
                    label="Submitting..."
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              {isButtonDisabled === false ? (
                <Button
                  label="Fetch SP-GOLD"
                  type="button"
                  onClick={() => fetchSPGold()}
                  style={{ marginRight: 8 }}
                />
              ) : (
                <Button
                  type="button"
                  label="Proccessing..."
                  icon="pi pi-spin pi-spinner"
                />
              )}
              {/* <Button label="Fetch Seniour SP-GOLD" style={{marginRight:5}} onClick={()=>fetchSPGold()} /> */}
              {/* <Button label="CHECK ROI" onClick={() => modifier()} /> */}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
