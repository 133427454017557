import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import "./home.scss";
import { Card } from "primereact/card";
export const Home = () => {
  return (
    <div className="homepg">
      <div className="grid hero">
        <div className="col-12 md:col-6 lg:col-7">
          <div className="p-1 border-round-sm font-bold">
            <Card className="left">
              <img src="/logo.png" alt="" />
              <div className="title">
                Together we can change the world! Lets fly together
              </div>
              <div className="txt">
                100% Automated, Robotic, Self Executed Platform.
              </div>
              <div className="button">
                <Button
                  label="Log In"
                  icon="pi pi-users"
                  severity="info"
                  onClick={() => (window.location.href = "/login")}
                />
                
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-5">
          <div className="p-0 border-round-sm font-bold">
            <Card className="right">
              <img src="/dapp-that-app.jpg"  alt="" />
            </Card>
          </div>
        </div>
      </div>

      <div className="grid technology">
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card className="left">
                <div className="title">Blockchain</div>
                <p>A blockchain is “a distributed database that maintains a continuously growing list of ordered records, called blocks.” These blocks “are linked using cryptography. Each block contains a cryptographic hash of the previous block, a timestamp, and transaction data. A blockchain is a decentralized, distributed and public digital ledger that is used to record transactions across many computers so that the record cannot be altered retroactively without the alteration of all subsequent blocks and the consensus of the network.</p>
                <img src="/soarxscan.png" alt="" width="100%" />
                <img src="/blockchain.png" alt="" width="100%" />
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card className="right">
            <div className="title">Smart Contract</div>
            <img src="/smartcontract.webp" width="100%" alt="" />
            <p>A smart contract is a self-executing program that automates the actions required in an agreement or contract. Once completed, the transactions are trackable and irreversible.</p>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card className="right">
              <div className="title">DAAP</div>
              <p>Decentralized applications or dApps are distributed, decentralized open-source software applications that run on a decentralized peer-to-peer network. Imagine the Twitter application that you have on your phone. You can post anything you want on Twitter but ultimately it’s controlled by a single company that can delete your tweets if they violate community guidelines or some other reason. But if there was a Twitter-type dApp, then it would be decentralized and not owned by any one person. If you posted something there, nobody would be able to delete it including its creators.</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
