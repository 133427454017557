import React, { useEffect, useState } from "react";
import "./weeklygbot.scss";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment"
import { Button } from "primereact/button";
export const WeeklyGbot = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getgbot");
      setProducts(resp.data.level);
    };
    fetchData();
  }, []);
  const handleSubmit = () => {
    const fetchData = async () => {
      setButtonDisabled(true)
      const resp = await axios.post(url + "/admin/setgbot",{
        wa:"1"
      })
      if(resp.status===200){
        setButtonDisabled(false)
      }else{
        setButtonDisabled(false)
      }
    }
    fetchData();
    
  }
  return (
    <div className="weeklygbot">
      {isButtonDisabled === false ? (
          <Button
            label="Submit"
            type="button"
            onClick={() => handleSubmit()}
          />
        ) : (
          <Button
            type="button"
            label="Processing..."
            icon="pi pi-spin pi-spinner"
          />
        )}
      <div className="card" style={{ marginTop: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SL No</TableCell>
                <TableCell>Tran Date</TableCell>
                <TableCell align="left">Last Paid</TableCell>
                <TableCell align="left">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{index*1+1}</TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.tran_date).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="left">{moment(row.last_tran_date).format("YYYY-MM-DD")}</TableCell>
                  <TableCell align="left">{(row.usdtval).toFixed(2)}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
